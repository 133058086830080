import React from 'react';
import cn from 'classnames';

// import AnimatedButton from '../../../../components/AnimatedButton/AnimatedButton';
import { MAX_BUST_PRICE, MIN_WAGER } from '../../../../constants/constatnts';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { BetType, Prediction } from '../../../../types/entities';

import styles from './PlaceBet.module.scss';

interface PlaceBetProps {
  betType: string;
  priceWillGo: string;
  bustPrice: number;
  betValue: number;
  multiplierValue: number;
  takePrice: string;
  closePrice: string;
  widgetOpened?: boolean;
  isErrorTakeCurrentPrice: boolean;
  isErrorCloseCurrentPrice: boolean;
  isErrorCloseBustPrice: boolean;
  isErrorCloseLoss: boolean;
  takeProfit: string;
  closeLoss: string;
  isSubmitPressed: boolean;
  onClick: () => void;
}

export default function PlaceBet({
  betType,
  bustPrice,
  priceWillGo,
  betValue,
  widgetOpened,
  multiplierValue,
  takePrice,
  closePrice,
  isErrorTakeCurrentPrice,
  isErrorCloseCurrentPrice,
  isErrorCloseBustPrice,
  isErrorCloseLoss,
  takeProfit,
  closeLoss,
  isSubmitPressed,
  onClick,
}: PlaceBetProps) {
  const isButtonDisabled = bustPrice > MAX_BUST_PRICE || +betValue < MIN_WAGER || !multiplierValue;
  const isAutoBetDisabled =
    betType === BetType.AUTO &&
    (isErrorTakeCurrentPrice || isErrorCloseCurrentPrice || isErrorCloseBustPrice || isErrorCloseLoss);
  const { openBetPending } = useTypedSelector((state) => state.bet);

  const getButtonLabel = () => {
    if (!widgetOpened) return 'New Bet';
    return betType === BetType.MANUAL ? 'Place Bet' : 'Place Auto Bet';
  };

  const renderError = (condition: boolean, message: string) =>
    condition && <div className={styles.error}>{message}</div>;

  return (
    <div className={styles.placeBet}>
      {/*<AnimatedButton*/}
      {/*  onClick={onClick}*/}
      {/*  type={priceWillGo === Prediction.DOWN ? 'primaryRed' : 'primaryGreen'}*/}
      {/*  disabled={isButtonDisabled || isAutoBetDisabled || openBetPending}*/}
      {/*  label={getButtonLabel()}*/}
      {/*/>*/}
      <button
        className={cn(styles.button, priceWillGo === Prediction.DOWN && styles.button_down)}
        onClick={onClick}
        disabled={isButtonDisabled || isAutoBetDisabled || openBetPending}
      >
        <div className={cn(styles.button_inner, priceWillGo === Prediction.DOWN && styles.button_down_inner)}>
          {getButtonLabel()}
        </div>
      </button>
      {bustPrice > MAX_BUST_PRICE && (
        <div className={styles.error}>
          Maximum position is 5,000,000 <br />
          (wager * multiplier)
        </div>
      )}
      {renderError(bustPrice > MAX_BUST_PRICE, 'Maximum position is 5,000,000 (wager * multiplier)')}
      {renderError(+betValue < MIN_WAGER, 'Minimum wager is 0.01')}
      {renderError(!multiplierValue, 'Minimum multiplier is 1')}
      {renderError(
        !takePrice && !takeProfit && betType === BetType.AUTO && isSubmitPressed,
        'Take profit at price is required',
      )}
      {renderError(
        !closePrice && !closeLoss && betType === BetType.AUTO && isSubmitPressed,
        'Close bet at price is required',
      )}
    </div>
  );
}
