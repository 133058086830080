import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { AvailableAsset } from '../../store/assets/types';
import CommonButton from '../CommonButton/CommonButton';
import FormattedAmountShort from '../FormattedAmount/FormattedAmountShort';
import { priceFormat } from '../LightWeightChart/helpers';

import styles from './CoinMenuItem.module.scss';

type CoinMenuItemProps = {
  asset: AvailableAsset;
  onClickItem: (asset: AvailableAsset) => void;
};

export default function CoinMenuItem({ asset, onClickItem }: CoinMenuItemProps) {
  const { cpd, icon, lp, multiplier, name, symbol, v } = asset;
  const grow = useMemo(() => Number(cpd) > 0, [cpd]);
  const down = useMemo(() => Number(cpd) < 0, [cpd]);
  const onTradeClick = useCallback(() => {}, []);

  const pathName = name.split('/')[0] || '';

  const urlParams = window.location.search;

  return (
    <Link to={`/${pathName}/active-bets${urlParams}`} onClick={() => onClickItem(asset)}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <img alt='' src={icon} className={styles.icon} />
          <div className={styles.nameBlock}>
            <span className={styles.title}>{name}</span>
            <span className={styles.name}>{symbol}</span>
          </div>
          <div className={styles.valueBlock} style={{ width: 120 }}>
            <span className={styles.value}>{priceFormat(Number(lp))}</span>
            <span className={styles.label}>Price</span>
          </div>
          <div className={styles.valueBlock}>
            <span className={styles.value}>{`${multiplier}x`}</span>
            <span className={styles.label}>Multiplier</span>
          </div>
          <div className={styles.valueBlock} style={{ width: 150 }}>
            {/*<span className={styles.value}>${v}</span>*/}
            <span className={styles.value}>
              <FormattedAmountShort amount={v ? Number(v) : 0} showCurrency={true} />
            </span>

            <span className={styles.label}>24h Vol</span>
          </div>
          <div className={styles.valueBlock}>
            <span
              className={cn(styles.change, grow && styles.change_green, down && styles.change_red)}
            >{`${grow ? '+' : down ? '-' : ''}${Math.abs(Number(cpd)).toFixed(2)}%`}</span>
            <span className={styles.label}>Change</span>
          </div>
          <div>{/*  coin chart */}</div>
          <CommonButton type='primaryRed' onClick={onTradeClick} label='Trade' width={72} height={38} />
        </div>
      </div>
    </Link>
  );
}
