import React from 'react';
import { UTCTimestamp } from 'lightweight-charts';

const oneMinute = 1000 * 60;

export interface Bar {
  time: number; // in ms (Date.now())
  open: number;
  high: number;
  low: number;
  close: number;
}

export function applyHistoryBars(bars: Bar[], seriesRef: any) {
  if (!bars.length || !seriesRef.current) return;

  const smoothed = smoothHistoricalDataCurved(bars, 6);

  const lineData = toLineDataSec(smoothed, 0.2);
  const data = removeCloseOutliers(lineData, 4);

  seriesRef.current.setData(data);
}

function removeCloseOutliers(bars: Bar[], zThreshold = 4): Bar[] {
  if (bars.length < 2) {
    return bars;
  }

  // 1. Gather all close prices
  const closes = bars.map((b) => b.close);

  // 2. Compute mean
  const mean = closes.reduce((sum, price) => sum + price, 0) / closes.length;

  // 3. Compute sample standard deviation
  const variance = closes.reduce((sum, price) => sum + (price - mean) ** 2, 0) / (closes.length - 1);
  const stdDev = Math.sqrt(variance);

  // 4. Filter out bars that are too far from mean
  return bars.filter((b) => {
    const zScore = Math.abs(b.close - mean) / stdDev;
    return zScore <= zThreshold; // keep if within threshold
  });
}

function smoothHistoricalDataCurved(bars: Bar[], steps = 5): Bar[] {
  if (bars.length < 3) {
    return bars;
  }

  const smoothed: Bar[] = [];
  function catmullRom(p0: number, p1: number, p2: number, p3: number, t: number) {
    const t2 = t * t;
    const t3 = t2 * t;

    return 0.5 * (2 * p1 + (-p0 + p2) * t + (2 * p0 - 5 * p1 + 4 * p2 - p3) * t2 + (-p0 + 3 * p1 - 3 * p2 + p3) * t3);
  }

  for (let i = 0; i < bars.length - 1; i++) {
    const p1 = bars[i];
    const p2 = bars[i + 1];

    const p0 = i === 0 ? p1 : bars[i - 1];
    const p3 = i + 2 === bars.length ? p2 : bars[i + 2];

    smoothed.push(p1);

    for (let step = 1; step < steps; step++) {
      const t = step / steps;

      const time = catmullRom(p0.time, p1.time, p2.time, p3.time, t /*, tension=0.5  */);

      const close = catmullRom(p0.close, p1.close, p2.close, p3.close, t /*, tension=0.5 */);

      smoothed.push({
        time: Math.round(time),
        close,
      } as any);
    }
  }

  smoothed.push(bars[bars.length - 1]);

  return smoothed;
}

export function getSmoothBarsCurvedLive(
  lastBar: Bar,
  newPrice: number,
  newTime: number,
  barHistoryRef: React.RefObject<Bar[]>,
  steps = 6,
): Bar[] {
  if (!lastBar || !barHistoryRef.current) return [];

  const smoothArr: Bar[] = [];

  // Store the last few bars for better curve continuity
  if (barHistoryRef.current.length > 10) barHistoryRef.current.shift(); // Keep the last 10 bars
  barHistoryRef.current.push(lastBar);

  // Get the second-last bar properly from history
  const secondLastBar =
    barHistoryRef.current.length > 1 ? barHistoryRef.current[barHistoryRef.current.length - 2] : lastBar;

  const p0 = secondLastBar;
  const p1 = lastBar;
  const p2 = { time: newTime, close: newPrice };

  // Predict future price based on last movement
  const p3 = {
    time: newTime + (newTime - lastBar.time),
    close: newPrice + (newPrice - lastBar.close),
  };

  function catmullRom(p0v: number, p1v: number, p2v: number, p3v: number, t: number) {
    const t2 = t * t;
    const t3 = t2 * t;

    return (
      0.5 *
      (2 * p1v + (-p0v + p2v) * t + (2 * p0v - 5 * p1v + 4 * p2v - p3v) * t2 + (-p0v + 3 * p1v - 3 * p2v + p3v) * t3)
    );
  }

  for (let i = 1; i <= steps; i++) {
    const t = i / steps;

    const interpTime = catmullRom(p0.time, p1.time, p2.time, p3.time, t);
    const interpClose = catmullRom(p0.close, p1.close, p2.close, p3.close, t);

    smoothArr.push({
      time: Math.round(interpTime),
      open: interpClose,
      high: interpClose,
      low: interpClose,
      close: interpClose,
    });
  }

  return smoothArr;
}

export function toLineDataSec(bars: Bar[], sec: number) {
  const result = [];
  let prevSec = -Infinity;

  for (const b of bars) {
    let s = b.time / 1000;
    if (s <= prevSec) {
      s = prevSec + sec;
    }
    result.push({
      ...b,
      time: s as UTCTimestamp,
      value: b.close,
    });
    prevSec = s;
  }
  return result;
}

export const getNewBar = (resolution: string, lastItemBar: Bar, tradePrice: number, tradeTime: number) => {
  const nextBarTime = getNextBarTime(lastItemBar.time, resolution);

  let newBarTime = nextBarTime;

  if (tradeTime >= nextBarTime) {
    const delta = getTimeDelta(resolution);
    if (tradeTime - nextBarTime > delta) {
      newBarTime = tradeTime - (tradeTime % delta);
    }
    return {
      time: newBarTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
  } else {
    return {
      ...lastItemBar,
      high: Math.max(lastItemBar.high, tradePrice),
      low: Math.min(lastItemBar.low, tradePrice),
      close: tradePrice,
    };
  }
};

function getNextBarTime(barTime: number, resolution: string) {
  switch (resolution) {
    case '1T': {
      return new Date(barTime + 200).getTime();
    }
    case '5S': {
      return new Date(barTime + 5000).getTime();
    }
    case '15S': {
      return new Date(barTime + 15000).getTime();
    }
    case '30S': {
      return new Date(barTime + 30000).getTime();
    }
    case '1': {
      return new Date(barTime + oneMinute).getTime();
    }
    case '5': {
      return new Date(barTime + oneMinute * 5).getTime();
    }
    case '15': {
      return new Date(barTime + oneMinute * 15).getTime();
    }
    case '30': {
      return new Date(barTime + oneMinute * 30).getTime();
    }
    case '60': {
      const date = new Date(barTime);
      date.setHours(date.getHours() + 1);
      return new Date(barTime + oneMinute * 60).getTime();
    }
    case '240': {
      return new Date(barTime + oneMinute * 240).getTime();
    }
    case '1D': {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
    default: {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
  }
}

function getTimeDelta(resolution: string) {
  switch (resolution) {
    case '1T': {
      return 1000;
    }
    case '5S': {
      return 5000;
    }
    case '15S': {
      return 15000;
    }
    case '30S': {
      return 30000;
    }
    case '1': {
      return oneMinute;
    }
    case '5': {
      return oneMinute * 5;
    }
    default:
      return oneMinute;
  }
}

export function defaultTickMarkFormatter(timePoint: any, tickMarkType: any, locale: any) {
  const formatOptions = {} as any;

  switch (tickMarkType) {
    case 0: //TickMarkType.Year:
      formatOptions.year = 'numeric';
      break;

    case 1: // TickMarkType.Month:
      formatOptions.month = 'short';
      break;

    case 2: //TickMarkType.DayOfMonth:
      formatOptions.day = 'numeric';
      break;

    case 3: //TickMarkType.Time:
      formatOptions.hour12 = false;
      formatOptions.hour = '2-digit';
      formatOptions.minute = '2-digit';
      break;

    case 4: //TickMarkType.TimeWithSeconds:
      formatOptions.hour12 = false;
      formatOptions.hour = '2-digit';
      formatOptions.minute = '2-digit';
      formatOptions.second = '2-digit';
      break;

    default:
    // ensureNever(tickMarkType);
  }

  const date =
    timePoint.businessDay === undefined
      ? new Date(timePoint.timestamp * 1000)
      : new Date(Date.UTC(timePoint.businessDay.year, timePoint.businessDay.month - 1, timePoint.businessDay.day));

  // from given date we should use only as UTC date or timestamp
  // but to format as locale date we can convert UTC date to local date
  const localDateFromUtc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );

  return localDateFromUtc.toLocaleString(locale, formatOptions);
}

export const priceFormat = (price: number) => {
  let minDigits = 2;
  let maxDigits = 2;

  if (price < 5) {
    minDigits = 4;
    maxDigits = 4;
  }
  if (price < 1) {
    minDigits = 6;
    maxDigits = 6;
  }

  const newPrice = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(price);

  return newPrice;
};
