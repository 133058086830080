import { useRef, useState } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
const { REACT_APP_FINANCE_SERVICE } = process.env;

export const useShareImage = (betId: any) => {
  const [shareImageLink, setShareImageLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const captureRef = useRef<HTMLDivElement | null>(null);

  const uploadImage = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${REACT_APP_FINANCE_SERVICE}/api/bets/${betId}/share-card`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const captureScreenshot = async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current, {
        backgroundColor: '#1a1f2c',
        scale: window.devicePixelRatio,
        useCORS: true,
      });

      return new Promise<string | null>((resolve) => {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], 'screenshot.png', { type: 'image/png' });
            const res = await uploadImage(file);
            const url = res?.url || null;
            setShareImageLink(url || '');
            resolve(url);
          } else {
            resolve(null);
          }
        }, 'image/png');
      });
    }
    return null;
  };

  const getShareImage = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${REACT_APP_FINANCE_SERVICE}/api/bets/${betId}/share-card`);
      setShareImageLink(res.data.url);
    } catch (error: any) {
      if (error.response?.data?.status === 'NOT_FOUND') {
        await captureScreenshot();
      } else {
        console.error('Error fetching share image:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onOpenShareBetPopup = async () => {
    setShareImageLink('');
    await getShareImage();
  };

  const clearShareImageLink = () => {
    setShareImageLink('');
  };

  return { shareImageLink, captureRef, loading, onOpenShareBetPopup, clearShareImageLink };
};
