import React, { useCallback, useMemo, useState } from 'react';
import { Menu, MenuProps } from 'antd';

import { RESOLUTIONS } from '../../../constants/constatnts';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { setResolution } from '../../../store/assets/slices';
import BottomDrawer from '../../BottomDriver/BottomDrawer';
import CustomSelect from '../../CustomSelect/CustomSelect';

import styles from './ResolutionSelector.module.scss';

export default function ResolutionSelector() {
  const { resolution } = useTypedSelector((state) => state.assets);
  const dispatch = useAppDispatch();
  const [tickSelectorMenuOpen, setTickSelectorMenuOpen] = useState(false);
  const { isMobile } = useWindowSize();

  const options = useMemo(() => {
    return Object.entries(RESOLUTIONS).map((item) => ({ value: item[1], label: item[0] }));
  }, []);

  const items: MenuProps['items'] = useMemo(
    () =>
      Object.entries(RESOLUTIONS).map((item) => ({
        key: item[1],
        label: item[0],
      })),
    [],
  );

  const onSelect = useCallback(
    (v: any) => {
      if (isMobile) {
        dispatch(setResolution(v?.key));
        setTickSelectorMenuOpen(false);
      } else {
        dispatch(setResolution(v));
      }
    },
    [dispatch, isMobile],
  );

  const openDrawer = useCallback(() => {
    setTickSelectorMenuOpen(true);
  }, []);

  const closeDrawer = useCallback(() => setTickSelectorMenuOpen(false), []);

  return isMobile ? (
    <>
      <div onClick={openDrawer}>
        <CustomSelect options={options} width={80} className={styles.selector} value={resolution} />
      </div>
      <BottomDrawer visible={tickSelectorMenuOpen} closeDriver={closeDrawer} height={270}>
        <Menu
          items={items}
          onClick={onSelect}
          defaultSelectedKeys={[resolution as any]}
          mode='inline'
          className={styles.menu}
        />
      </BottomDrawer>
    </>
  ) : (
    <CustomSelect options={options} onSelect={onSelect} width={100} className={styles.selector} value={resolution} />
  );
}
