import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { SearchIcon } from '../../assets/icons';
import CoinMenuItem from '../../components/CoinMenuItem/CoinMenuItem';
import FormattedAmountShort from '../../components/FormattedAmount/FormattedAmountShort';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { setCurrentAsset } from '../../store/assets/slices';
import { AvailableAsset } from '../../store/assets/types';

import styles from './CryptoList.module.scss';

export default function CryptoList() {
  const availableAssets = useTypedSelector((state) => state.assets.availableAssets);
  const dispatch = useAppDispatch();

  const handleClickListItem = useCallback(
    (asset: AvailableAsset) => {
      dispatch(setCurrentAsset(asset));
    },
    [dispatch],
  );

  const global24HVolume = useMemo(() => {
    return availableAssets.reduce((prevValue, currentValue) => {
      return currentValue.v + prevValue;
    }, 0);
  }, [availableAssets]);

  const globalPercentIncreasedByDay = useMemo(() => {
    return availableAssets.reduce((prevValue, currentValue) => {
      return currentValue.cpd + prevValue;
    }, 0);
  }, [availableAssets]);

  const grow = useMemo(() => Number(globalPercentIncreasedByDay) > 0, [globalPercentIncreasedByDay]);
  const down = useMemo(() => Number(globalPercentIncreasedByDay) < 0, [globalPercentIncreasedByDay]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <div className={styles.leftBlock}>
          <span className={styles.headerText}>Crypto Futures</span>
          <div className={styles.subHeader}>
            <span>
              <span className={styles.labelText}>Global 24h Volume: </span>
              <span className={styles.valueText}>
                {' '}
                <FormattedAmountShort amount={global24HVolume} showCurrency={true} />
              </span>
            </span>
            <span>
              <span className={styles.labelText}>Increased by: </span>
              <span
                className={cn(styles.valueText, grow && styles.valueText_green, down && styles.valueText_red)}
              >{`${grow ? '+' : down ? '-' : ''}${Math.abs(Number(globalPercentIncreasedByDay)).toFixed(2)}%`}</span>{' '}
              <span className={cn(styles.labelText, styles.period)}>(1 day)</span>
            </span>
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <SearchIcon />
            <input type='text' placeholder='Search for coins...' />
          </div>
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {Array.isArray(availableAssets) &&
          availableAssets.map((item) => <CoinMenuItem asset={item} onClickItem={handleClickListItem} key={item.id} />)}
      </div>
    </div>
  );
}
