import React, { ChangeEvent } from 'react';
import { NumericFormat } from 'react-number-format';

import styles from './TakeProfitSetting.module.scss';

interface TakeProfitSettingProps {
  price: string;
  profit: string;
  placeholder?: string;
  error?: boolean | undefined;
  errorText?: string;
  onChangeTakePrice: (value: string) => void;
  onChangeTakeProfit: (value: string) => void;
  onTakePriceFocus?: () => void;
  onTakeProfitFocus?: () => void;
}

export default function TakeProfitSetting({
  price,
  profit,
  placeholder,
  error,
  errorText,
  onChangeTakePrice,
  onChangeTakeProfit,
  onTakePriceFocus,
  onTakeProfitFocus,
}: TakeProfitSettingProps) {
  return (
    <div className={styles.takeProfit}>
      <p className={styles.label}>Take profit at price / profit</p>
      <div className={styles.blocks}>
        <div className={styles.blockWrapper}>
          <div className={styles.block}>
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={false}
              allowNegative={false}
              prefix={''}
              placeholder={placeholder}
              inputMode='numeric'
              value={price}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTakePrice?.(e.target.value)}
              onFocus={onTakePriceFocus}
            />
          </div>
        </div>
        <div className={styles.blockWrapper}>
          <div className={styles.block}>
            <span>+$</span>
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={false}
              allowNegative={false}
              prefix={''}
              placeholder={placeholder}
              inputMode='numeric'
              value={profit}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTakeProfit?.(e.target.value)}
              onFocus={onTakeProfitFocus}
              style={{ paddingLeft: 34 }}
            />
          </div>
        </div>
      </div>
      {error && <div className={styles.block_error}>{errorText}</div>}
    </div>
  );
}
