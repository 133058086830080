import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConfigProvider, Table } from 'antd';
import { useDebouncedCallback } from 'use-debounce';

import { requester } from '../../api/axiosInstance';
import FormattedAmount from '../../components/FormattedAmount/FormattedAmount';
import TableLoader from '../../components/Loader/TableLoader';
import ActiveBetActions from '../../components/TableCells/ActiveBetActionButtons/ActiveBetActions';
import ActiveBetCashOutButton from '../../components/TableCells/ActiveBetActionButtons/ActiveBetCashOutButton';
import ActiveBetFundingCell from '../../components/TableCells/ActiveBetFundingCell/ActiveBetFundingCell';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import { getDecimalPlaces } from '../../helpers/getDecimalPlaces';
import { useShowMessage } from '../../helpers/messages';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { setActiveBetsPagination } from '../../store/betList/slices';
import { ActiveBet } from '../../types/entities';

import EmptyState from './EmptyState/EmptyState';

import styles from '../../styles/table.module.scss';

interface BodyWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  // antd може передавати інші поля
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  data?: any;
}

const VirtualBodyWrapper = forwardRef<HTMLDivElement, BodyWrapperProps>((props, ref) => {
  const { className, style, children, ...restProps } = props;
  const dispatch = useDispatch();

  const debouncedLoadData = useDebouncedCallback(() => {
    dispatch(setActiveBetsPagination());
  }, 400);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (scrollHeight - scrollTop - clientHeight < 30) {
        debouncedLoadData();
      }
    },
    [debouncedLoadData],
  );

  return (
    <div ref={ref} id='dgd' className={className} style={style} onScrollCapture={handleScroll} {...restProps}>
      {children}
    </div>
  );
});
export default function ActiveBets() {
  const { showError, contextHolder } = useShowMessage();
  const [pendingBets, setPendingBets] = useState<number[]>([]);
  const { isMobile } = useWindowSize();

  const { activeBets, loading } = useTypedSelector((state) => state.betList);

  const onCashOut = useCallback(async (bet: ActiveBet) => {
    setPendingBets((arr: number[]) => [...arr, bet?.id]);
    try {
      const result = await requester.post(`/bets/${bet.id}/cashout`);
    } catch (e) {
      showError('Something went wrong, try again');
      setPendingBets((arr) => arr.filter((el) => el !== bet?.id));
    }
    setTimeout(() => {
      setPendingBets((arr) => arr.filter((el) => el !== bet?.id));
    }, 60000);
  }, []);

  const commonColumns = [
    {
      title: 'Bet',
      key: 'playerCurrency',
      width: 150,
      render: (_: any, record: ActiveBet) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
      className: styles.header,
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      width: 80,
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      width: 100,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bust',
      width: 100,
      render: (price: number, record: any) => {
        const fractionDigits = getDecimalPlaces(record.openPrice);
        return <FormattedAmount amount={price} fractionDigits={fractionDigits} />;
      },
    },
    // {
    //   title: 'Funding/hour',
    //   key: 'fund',
    //   width: 110,
    //   render: (_: any, record: ActiveBet) => <ActiveBetFundingCell bet={record} />,
    // },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      render: (value: number) => <PNLCell value={value} />,
      width: 100,
    },
  ];

  const columns = isMobile
    ? [
        ...commonColumns,
        {
          title: '',
          render: (_: any, bet: any) => <ActiveBetActions bet={bet} />,
          width: 72,
        },
        {
          title: '',
          render: (_: any, bet: any) => (
            <ActiveBetCashOutButton onCashOut={() => onCashOut(bet)} loading={pendingBets.includes(bet?.id)} />
          ),
          width: 113,
          fixed: 'right' as any,
        },
      ]
    : [
        ...commonColumns,
        {
          title: '',
          render: (_: any, bet: any) => (
            <ActiveBetCashOutButton onCashOut={() => onCashOut(bet)} loading={pendingBets.includes(bet?.id)} />
          ),
          width: 109,
        },
        {
          title: '',
          render: (_: any, bet: ActiveBet) => <ActiveBetActions bet={bet} />,
          width: 72,
        },
      ];

  const renderEmptyState = useCallback(
    // () => (loading ? <TableLoader isLoading={true} /> : <EmptyState description={['No active bets']} smallImg />),
    () =>
      loading ? (
        <TableLoader isLoading={true} />
      ) : (
        <EmptyState
          title={'Create a new bet'}
          text={'It looks like the bet list is empty right now. Start by placing your first bets!'}
        />
      ),
    [loading],
  );

  // useEffect(() => {
  //   function onWindowScroll() {
  //     const scrollPosition = window.scrollY + window.innerHeight;
  //     const pageHeight = document.documentElement.scrollHeight;
  //     if (pageHeight - scrollPosition < 150) {
  //       //
  //       debouncedLoadData();
  //     }
  //   }
  //   window.addEventListener('scroll', onWindowScroll);
  //   return () => window.removeEventListener('scroll', onWindowScroll);
  // }, [debouncedLoadData]);

  return (
    <ConfigProvider renderEmpty={renderEmptyState}>
      {contextHolder}
      <div className={styles.tableWrapper}>
        <Table
          rowClassName={styles.row}
          dataSource={activeBets}
          columns={columns}
          virtual={true}
          pagination={false}
          rowKey='id'
          className={styles.table}
          scroll={{ x: isMobile ? 500 : 955, y: 600 }}
          components={{
            body: {
              wrapper: VirtualBodyWrapper,
            },
          }}
        />
      </div>
    </ConfigProvider>
  );
}
