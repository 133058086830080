import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { ConfigProvider, Dropdown, Menu, MenuProps } from 'antd';
import cn from 'classnames';

import { ArrowUpSecondaryIcon } from '../../../assets/icons';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { setCurrentAsset } from '../../../store/assets/slices';
import { AvailableAsset } from '../../../store/assets/types';
import BottomDrawer from '../../BottomDriver/BottomDrawer';

import CoinItem from './CoinItem';

import styles from './CoinSelector.module.scss';

export default function CoinSelector() {
  const { currentAsset, availableAssets: assets } = useTypedSelector((state) => state.assets);
  const dispatch = useAppDispatch();
  const [coinSelectorMenuOpen, setCoinSelectorMenuOpen] = useState(false);
  const [listOpened, setListOpened] = useState(false);
  const { isMobile, height: windowHeight } = useWindowSize();
  const [listHeight, setListHeight] = useState(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onSelectCoin = useCallback(
    (coin: AvailableAsset) => {
      const currencyName = coin.name.split('/')[0] || 'Crypto';

      const pathParts = pathname.split('/');
      if (pathParts.length > 1) {
        pathParts[1] = currencyName;
        const urlParams = window.location.search || '';
        navigate(pathParts.join('/') + urlParams);
      }

      dispatch(
        setCurrentAsset({
          ...coin,
          lastPrice: coin.lp,
        }),
      );
    },
    [dispatch, navigate, pathname],
  );

  const onOpen = useCallback((opened: boolean) => {
    setListOpened(opened);
  }, []);

  const openDrawer = useCallback(() => {
    setCoinSelectorMenuOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setCoinSelectorMenuOpen(false);
  }, []);

  const items: MenuProps['items'] = useMemo(
    () =>
      assets?.map((coin) => ({
        key: coin.id,
        label: <CoinItem onSelect={() => onSelectCoin(coin)} coin={coin} />,
      })),
    [assets, onSelectCoin],
  );

  useEffect(() => {
    if (assets?.length > 0) {
      const computed = assets.length * 40 + 90;
      setListHeight(computed > windowHeight - 50 ? windowHeight - 50 : computed);
    }
  }, [assets, windowHeight]);

  if (isMobile)
    return (
      <>
        <div className={styles.coinWrapper} onClick={openDrawer}>
          <img src={currentAsset?.icon} alt='' className={styles.coinImage} />
          <DownOutlined className={cn(styles.arrow, listOpened && styles.arrow_up)} />
        </div>
        <BottomDrawer visible={coinSelectorMenuOpen} closeDriver={closeDrawer} height={listHeight}>
          <div className={styles.drawerInnerWrapper}>
            <span className={styles.header}>Market</span>
            <div className={styles.listContainer} style={{ height: `${listHeight - 65}px` }}>
              <div className={styles.tableHeader}>
                <span className={styles.pair}>Pair</span>
                <span className={styles.price}>Price</span>
                <span className={styles.change}>24h Change</span>
              </div>

              <Menu
                onClick={closeDrawer}
                defaultSelectedKeys={[String(currentAsset?.id)]}
                mode='inline'
                items={items}
              />
            </div>
          </div>
        </BottomDrawer>
      </>
    );

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Dropdown: {
              paddingBlock: 8,
              controlItemBgHover: '#4B5063',
              controlItemBgActive: '#35394A',
              controlItemBgActiveHover: '#4B5063',
            },
          },
        }}
      >
        <Dropdown
          onOpenChange={onOpen}
          trigger={'click' as any}
          overlayClassName={styles.dropdownContainer}
          menu={{
            items,
          }}
          dropdownRender={(menu) => (
            <div>
              <div className={styles.tableHeader}>
                <span className={styles.pair}>Pair</span>
                <span className={styles.price}>Price</span>
                <span className={styles.change}>24h Change</span>
              </div>
              {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
            </div>
          )}
        >
          <div className={styles.coinWrapper}>
            <img src={currentAsset?.icon} alt='' className={styles.coinImage} />
            {!isMobile && <span className={styles.coinHeader}>{currentAsset?.name}</span>}
            <ArrowUpSecondaryIcon
              style={{ transform: listOpened ? 'scaleY(1)' : 'scaleY(-1)', transition: '0.3s all', color: '#fff' }}
            />
          </div>
        </Dropdown>
      </ConfigProvider>
    </div>
  );
}
