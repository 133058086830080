import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { AvailableAsset } from '../../../store/assets/types';
import { priceFormat } from '../../LightWeightChart/helpers';

import styles from './CoinSelector.module.scss';

type CoinItemProps = {
  coin: AvailableAsset;
  onSelect: () => void;
};

export default function CoinItem({ coin, onSelect }: CoinItemProps) {
  const grow = useMemo(() => Number(coin?.cpd) > 0, [coin?.cpd]);
  const down = useMemo(() => Number(coin?.cpd) < 0, [coin?.cpd]);

  const handleClick = useCallback(() => {
    onSelect();
  }, [onSelect]);

  const [coinFirstPart, coinSecondPart] = coin.name.split('/');

  return (
    <div onClick={handleClick} className={styles.row}>
      <span className={styles.pair}>
        <img src={coin.icon} alt='' className={styles.icon} />
        <span className={styles.coinName}>
          {coinFirstPart}
          &nbsp; / &nbsp;
          <span className={styles.coinNameOpacity}>{coinSecondPart}</span>
        </span>
      </span>
      <>
        <span className={cn(styles.price, styles.price_value)}>{priceFormat(Number(coin.lp))}</span>
        {Number.isNaN(Number(coin?.cpd)) ? (
          <span className={styles.change} />
        ) : (
          <span
            className={cn(styles.change, grow && styles.change_green, down && styles.change_red)}
          >{`${grow ? '+' : down ? '-' : ''}${Math.abs(Number(coin?.cpd)).toFixed(2)}%`}</span>
        )}
      </>
    </div>
  );
}
