import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { DoubleRightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { ColorType, createChart, IChartApi, LineStyle, UTCTimestamp } from 'lightweight-charts';
import { useDebouncedCallback } from 'use-debounce';

import { getDefaultVisibleRange } from '../../helpers/intervals';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { PublicBet } from '../../types/entities';
import { BetMarker } from '../BetMarker';

import { useLoadOlderDataOnScroll } from './hooks/useLoadOlderDataOnScroll';
import { applyHistoryBars, defaultTickMarkFormatter, getNewBar, getSmoothBarsCurvedLive, priceFormat } from './helpers';
import { getBars } from './requests';

import style from './index.module.scss';

interface Bar {
  time: number; // мс
  open: number;
  high: number;
  low: number;
  close: number;
}

type BetLines = {
  entryPriceLine?: any;
  bustPriceLine?: any;
  takeProfitLine?: any;
  stopLossLine?: any;
};

interface MarkerPosition {
  x: number;
  y: number;
  id: number;
  bet: PublicBet;
}

interface FreshBetMarker extends PublicBet {
  closePrice: number;
  closedAtMs: number; // epoch ms
}

type ChartProps = {
  asset?: string;
  interval?: string | null; // '1T', '5S', '15S', '30S', '1', '5'
  decimalPlaces?: number;
};

const colorGreen = '#0E8A50';
const colorRed = '#C0180C';

const rightBarWidth = 280;
const outerPaddings = 80;
const innerPaddings = 48;
const columnGap = 16;
const tabletBp = 900;
const mobileBp = 500;
const mobilePaddings = 32;
const chartFixedHeightTabletUp = 413;
const chartFixedWidthTablet = 476;

const LightweightChart: FC<ChartProps> = ({ interval = '1T' }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const seriesRef = useRef<any>(null);
  const lastBarRef = useRef<Bar | null>(null);
  const timerId = useRef<any>(new Array(6).fill(null));
  const barHistoryRef = useRef<Bar[]>([]);

  const [chartReady, setChartReady] = useState(false);
  const [allBars, setAllBars] = useState<Bar[]>([]);
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [direction, setDirection] = useState('');
  const [betLines, setBetLines] = useState<BetLines>({});

  const [markerPositions, setMarkerPositions] = useState<MarkerPosition[]>([]);
  const freshBets = useRef<FreshBetMarker[]>([]);

  const { currentAsset } = useTypedSelector((state) => state.assets);
  const betOnAsset = useTypedSelector((state) => state.betList.betOnChart);
  const publicBetsList = useTypedSelector((state) => state.betList.publicBetsQueue);

  const { showMyBetsOnChart, showPublicBetsOnChart } = useTypedSelector((state) => state.ui);
  const playerId = useTypedSelector((state) => state.user.playerId);

  const symbol = useMemo(() => currentAsset?.symbol, [currentAsset?.symbol]);

  const prevPrice = usePrevious(currentAsset?.lastPrice);

  const clearIntervals = () => {
    if (timerId.current) {
      timerId.current.forEach((item: any) => {
        clearTimeout(item);
      });
    }
  };

  useEffect(() => {
    if (!chartContainerRef.current) return;
    const localTimezoneOffset = new Date().getTimezoneOffset() * 60;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      handleScale: true,
      handleScroll: true,
      layout: {
        background: { type: ColorType.Solid, color: '#0a0a0e' },
        textColor: '#9096AF',
      },
      grid: {
        vertLines: { color: '#35394A', visible: false, style: LineStyle.Solid },
        horzLines: { color: '#35394A', visible: false, style: LineStyle.Solid },
      },
      crosshair: { mode: 1 },
      timeScale: {
        timeVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          return defaultTickMarkFormatter({ timestamp: time - localTimezoneOffset }, tickMarkType, locale);
        },
      },
      localization: {
        timeFormatter: (timestamp: number) => {
          const date = new Date(timestamp * 1000);

          return date.toLocaleString('en-US', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour12: false,
          });
        },
      },
    });

    chartRef.current = chart;

    setChartReady(true);

    return () => {
      chart.remove();
      chartRef.current = null;
      if (barHistoryRef.current) {
        barHistoryRef.current = [];
      }
    };
  }, []);

  const applyDataToSeries = React.useCallback(
    (bars: Bar[]) => {
      if (timerId.current) {
        clearIntervals();
      }
      if (!seriesRef.current) return;
      if (interval === '1T') {
        applyHistoryBars(bars, seriesRef);
      } else {
        // candle

        const candleData = bars.map((b) => ({
          time: Math.floor(b.time / 1000) as UTCTimestamp,
          open: b.open,
          high: b.high,
          low: b.low,
          close: b.close,
        }));

        seriesRef.current.setData(candleData);
      }
    },
    [interval],
  );

  const createNeSeries = useCallback(() => {
    if (interval === '1T') {
      seriesRef.current = chartRef.current?.addAreaSeries({
        lineType: 2,
        lineWidth: 2,
        topColor: '#3e2b16',
        bottomColor: '#3e2b1600',
        lineColor: '#F7931A',
        lastPriceAnimation: 2,
        priceFormat: {
          type: 'custom',
          formatter: priceFormat,
          minMove: 0.000001,
        },
      });
    } else {
      seriesRef.current = chartRef.current?.addCandlestickSeries({
        upColor: colorGreen,
        downColor: colorRed,
        borderUpColor: colorGreen,
        wickUpColor: colorGreen,
        wickDownColor: colorRed,
        borderDownColor: colorRed,
        priceFormat: {
          type: 'custom',
          formatter: priceFormat,
        },
      });
    }

    const { from, to } = getDefaultVisibleRange(interval);

    // load initial
    getBars(
      { type: 'crypto', full_name: symbol },
      interval as any,
      { from, to, firstDataRequest: true },
      (bars) => {
        setAllBars(bars);
        applyDataToSeries(bars);

        if (!chartRef.current) return;

        const timeScale = chartRef.current.timeScale();

        setTimeout(() => {
          timeScale.setVisibleRange({
            from: from as UTCTimestamp,
            to: to as UTCTimestamp,
          });
          timeScale.scrollToRealTime();
        }, 100);

        setChartReady(true);
      },
      (err) => console.error('getBars error:', err),
    );
  }, [applyDataToSeries, interval, symbol]);

  useEffect(() => {
    if (!chartRef.current || !interval) return;

    setChartReady(false);

    if (timerId.current) {
      clearIntervals();
    }
    // remove old
    if (seriesRef.current) {
      chartRef.current.removeSeries(seriesRef.current);
      seriesRef.current = null;
    }

    if (barHistoryRef.current) {
      barHistoryRef.current = [];
    }
    setAllBars([]);
    lastBarRef.current = null;

    // create new series
    createNeSeries();
  }, [interval, createNeSeries]);

  //  live update
  useEffect(() => {
    if (!seriesRef.current || !chartReady) return;
    if (!currentAsset?.lastPrice || !currentAsset.updateTime) return;

    const tradePrice = Number(currentAsset.lastPrice);
    const tradeTime = Number(currentAsset.updateTime);
    if (Number.isNaN(tradePrice) || Number.isNaN(tradeTime)) return;

    if (timerId.current) {
      clearIntervals();
    }

    if (!lastBarRef.current) {
      // first bar
      const firstBar: Bar = {
        time: tradeTime,
        open: tradePrice,
        high: tradePrice,
        low: tradePrice,
        close: tradePrice,
      };
      lastBarRef.current = firstBar;
      if (interval === '1T') {
        if (lastBarRef.current && tradeTime <= lastBarRef.current?.time) {
          return;
        }
        seriesRef.current.update({
          time: Math.floor(tradeTime / 1000) as UTCTimestamp,
          value: tradePrice,
        });
      } else {
        seriesRef.current.update({
          time: Math.floor(tradeTime / 1000) as UTCTimestamp,
          open: tradePrice,
          high: tradePrice,
          low: tradePrice,
          close: tradePrice,
        });
      }
      return;
    }

    if (interval === '1T') {
      // line
      if (timerId.current) {
        clearIntervals();
      }

      const smoothArr = getSmoothBarsCurvedLive(lastBarRef.current, tradePrice, tradeTime, barHistoryRef);

      smoothArr.forEach((item, index) => {
        timerId.current[index] = setTimeout(() => {
          // If item is older or same time, skip
          if (lastBarRef.current && item.time <= lastBarRef.current.time) {
            return;
          }

          lastBarRef.current = item;
          seriesRef.current.update({
            ...item,
            value: item.close,
            time: item.time / 1000,
          });
        }, index * 50);
      });
    } else {
      if (timerId.current) {
        clearIntervals();
      }

      const bar = getNewBar(String(interval), lastBarRef.current, tradePrice, tradeTime);

      if (bar.time < lastBarRef.current.time) {
        return;
      }

      lastBarRef.current = bar;
      seriesRef.current.update({ ...bar, time: bar.time / 1000 });
    }
  }, [currentAsset, interval]);

  useEffect(() => {
    if (!prevPrice || !currentAsset?.lastPrice) return;
    if (prevPrice < currentAsset.lastPrice) setDirection('UP');
    else if (prevPrice > currentAsset.lastPrice) setDirection('DOWN');
  }, [currentAsset?.lastPrice, prevPrice]);

  useEffect(() => {
    if (!publicBetsList?.length) return;

    const newBets: FreshBetMarker[] = [];

    publicBetsList.forEach((bet) => {
      const closedAtMs = Date.parse(String(bet.closedAt));
      const deltaSec = (Date.now() - closedAtMs) / 1000;

      const found = freshBets.current.some((fb) => fb.id === bet.id);
      if (found) {
        return;
      }

      if (deltaSec < 0 || deltaSec > 15) {
        return;
      }

      const newBet: FreshBetMarker = {
        ...bet,
        id: bet.id,
        closePrice: Number(bet.closePrice),
        closedAtMs: closedAtMs,
      };

      const isUserBet = bet.playerId === Number(playerId);
      const isNotUserBet = !isUserBet;

      const showBet = (showMyBetsOnChart && isUserBet) || (showPublicBetsOnChart && isNotUserBet);

      if (!showBet) return;

      newBets.push(newBet);

      setTimeout(() => {
        if (freshBets.current) {
          const updatedBets = freshBets.current.filter((b) => b.id !== bet.id);

          freshBets.current = updatedBets;
        }
      }, 10000);
    });

    if (newBets.length) {
      if (freshBets.current) {
        freshBets.current = [...freshBets.current, ...newBets];
      }
    }
  }, [publicBetsList, playerId, showMyBetsOnChart, showPublicBetsOnChart]);

  const recalcPositions = useCallback(() => {
    if (!chartRef.current || !seriesRef.current || !chartContainerRef.current) return;
    if (freshBets.current.length === 0 && markerPositions.length > 0) {
      setMarkerPositions([]);
      return;
    }

    if (freshBets.current.length) {
      const chart = chartRef.current;
      const timeScale = chart.timeScale();
      const series = seriesRef.current;

      const visibleRange = timeScale.getVisibleRange();
      if (!visibleRange) return;

      const tMin = visibleRange.from;
      const tMax = visibleRange.to;

      const xMin = timeScale.timeToCoordinate(tMin) ?? 0;
      const xMax = timeScale.timeToCoordinate(tMax) ?? chartContainerRef.current.clientWidth;

      const newPositions: MarkerPosition[] = freshBets.current.map((bet) => {
        const closedAtSec = bet.closedAtMs / 1000;

        // @ts-ignore
        let x = xMin + ((closedAtSec - tMin) / (tMax - tMin)) * (xMax - xMin);
        let y = series.priceToCoordinate(bet.closePrice) ?? 0;

        return {
          id: bet.id,
          x,
          y,
          bet,
        };
      });

      setMarkerPositions(newPositions);
    }
  }, [markerPositions.length]);

  useEffect(() => {
    const element = document.querySelector('#betWidgetWrapper');

    const resizeObserver = new ResizeObserver((entries) => {
      if (chartRef.current && element) {
        const { innerWidth } = window;

        const isMobile = innerWidth <= mobileBp;
        const isTablet = innerWidth <= tabletBp;

        const chartWidthDesktopUp = innerWidth - rightBarWidth - outerPaddings - innerPaddings - columnGap;
        const chartWidthMobileDown = innerWidth - mobilePaddings - mobilePaddings;
        let chartWidth = 0;
        if (isMobile) {
          chartWidth = chartWidthMobileDown;
        } else if (isTablet) {
          chartWidth = chartFixedWidthTablet;
        } else {
          chartWidth = chartWidthDesktopUp;
        }

        const mobileContainer = document.querySelector('#chart-container-mobile');

        for (let entry of entries) {
          const { height } = entry.contentRect;

          const chartHeight = isMobile ? Number(mobileContainer?.clientHeight) - 24 - 32 - 24 - 18 || 0 : height - 150;

          requestAnimationFrame(() => {
            if (chartRef.current && chartHeight > 1) {
              chartRef.current.resize(chartWidth, chartHeight, true);
            }
          });
        }
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    if (currentAsset && currentAsset.updateTime && seriesRef.current) {
      if (interval === '1T') {
        seriesRef.current.setMarkers([
          {
            position: 'inBar',
            shape: 'circle',
            color: '#F7931A',
            size: 0.5,
            text: '',
            time: currentAsset.updateTime,
          },
        ]);
      }
    }
  }, [currentAsset]);

  //  older data load
  const loadOlderData = useCallback(async () => {
    if (!allBars.length) return;

    const MAX_HISTORY_HOURS = 10; // Limit to 10 hours
    const SECONDS_IN_HOUR = 3600;

    const latest = allBars[allBars.length - 1];
    const latestSec = Math.floor(latest.time / 1000);
    const minAllowedSec = latestSec - MAX_HISTORY_HOURS * SECONDS_IN_HOUR;

    const earliest = allBars[0];
    const earliestSec = Math.floor(earliest.time / 1000);
    const fromSec = earliestSec - 1200;
    const toSec = earliestSec;

    if (earliestSec <= minAllowedSec) {
      console.log('Reached max historical limit. No more data to load.');
      return;
    }

    return new Promise<void>((resolve, reject) => {
      getBars(
        { type: 'crypto', full_name: symbol },
        (interval as any) || '1T',
        { from: fromSec, to: toSec, firstDataRequest: false },
        (older) => {
          if (!older.length) {
            console.log('No older bars found');
            resolve();
            return;
          }
          const merged = [...older, ...allBars];
          merged.sort((a, b) => a.time - b.time);
          setAllBars(merged);
          applyDataToSeries(merged);
          resolve();
        },
        (err) => {
          console.error('loadOlderData error:', err);
          reject(err);
        },
      );
    });
  }, [interval, allBars, applyDataToSeries]);

  useLoadOlderDataOnScroll(chartRef.current?.timeScale() || null, loadOlderData, recalcPositions, 0);

  //  Move forward
  const moveForward = () => {
    if (!chartReady || !chartRef.current) return;
    const timeScale = chartRef.current.timeScale();
    const currentPos = timeScale.scrollPosition() ?? 0;
    const finalPos = 0;

    const duration = 1;
    const frameRate = 60;
    const totalFrames = duration * frameRate;
    const distance = currentPos - finalPos;
    const step = distance / totalFrames;

    let frame = 0;
    function animateScroll() {
      if (frame >= totalFrames) {
        timeScale.scrollToPosition(finalPos, false);
        return;
      }
      const newPos = currentPos - step * frame;
      timeScale.scrollToPosition(newPos, false);
      frame++;
      requestAnimationFrame(animateScroll);
    }
    animateScroll();
  };

  const onWindowResize = () => {
    if (!chartRef.current) {
      return;
    }

    const { innerWidth } = window;

    const isMobile = innerWidth <= mobileBp;
    const isTablet = innerWidth <= tabletBp;

    const chartWidthDesktopUp = innerWidth - rightBarWidth - outerPaddings - innerPaddings - columnGap;
    const chartWidthMobileDown = innerWidth - mobilePaddings - mobilePaddings;

    let chartWidth;

    if (!chartRef.current) {
      return;
    }
    const mobileContainer = document.querySelector('#chart-container-mobile');
    const chartHeight = isMobile
      ? Number(mobileContainer?.clientHeight) - 24 - 32 - 24 - 18 || 0
      : chartFixedHeightTabletUp;

    if (isMobile) {
      chartWidth = chartWidthMobileDown;
    } else if (isTablet) {
      chartWidth = chartFixedWidthTablet;
    } else {
      chartWidth = chartWidthDesktopUp;
    }

    chartRef.current.resize(chartWidth, chartHeight, true);
  };

  const debouncedWindowResize = useDebouncedCallback(onWindowResize, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedWindowResize);
    debouncedWindowResize();

    return () => {
      window.removeEventListener('resize', debouncedWindowResize);
      chartRef.current = null;
    };
  }, []);

  //  direction => priceLineColor (line only)
  useEffect(() => {
    if (!seriesRef.current) return;
    if (interval === '1T') {
      if (direction === 'UP') seriesRef.current.applyOptions({ priceLineColor: '#0E8A50' });
      else if (direction === 'DOWN') seriesRef.current.applyOptions({ priceLineColor: '#C0180C' });
    }
  }, [direction, interval]);

  // track forward button
  useEffect(() => {
    if (!chartRef.current) return;
    const timeScale = chartRef.current.timeScale();

    function handleRangeChange() {
      const pos = timeScale.scrollPosition() ?? 0;
      setCanMoveForward(pos < 0);
    }
    timeScale.subscribeVisibleLogicalRangeChange(handleRangeChange);
    handleRangeChange();

    return () => {
      timeScale.unsubscribeVisibleLogicalRangeChange(handleRangeChange);
    };
  }, [chartReady]);

  const priceLineRef = useRef<any>(null);

  useEffect(() => {
    if (canMoveForward) {
      if (!priceLineRef.current) {
        // Create the price line only once
        priceLineRef.current = seriesRef.current.createPriceLine({
          price: currentAsset?.lastPrice || 0,
          color: 'transparent',
          lineWidth: 1,
          lineStyle: LineStyle.Dotted,
          axisLabelVisible: true,
          title: priceFormat(Number(currentAsset?.lastPrice)),
        });

        seriesRef.current.applyOptions({ priceLineVisible: false, priceLineStyle: 1 });
      } else {
        // Update the existing price line instead of creating a new one
        priceLineRef.current.applyOptions({
          price: currentAsset?.lastPrice,
          title: '',
          color: direction === 'UP' ? '#0E8A50' : '#C0180C',
        });
      }
    } else {
      // Remove the price line when canMoveForward is false
      if (priceLineRef.current) {
        seriesRef.current.removePriceLine(priceLineRef.current);
        priceLineRef.current = null; // Reset the reference
        seriesRef.current.applyOptions({ priceLineVisible: true, priceLineStyle: 2 });
      }
    }
  }, [canMoveForward, currentAsset?.lastPrice]);

  useEffect(() => {
    if (!seriesRef.current) return;

    if (betLines.entryPriceLine) seriesRef.current.removePriceLine(betLines.entryPriceLine);
    if (betLines.bustPriceLine) seriesRef.current.removePriceLine(betLines.bustPriceLine);
    if (betLines.takeProfitLine) seriesRef.current.removePriceLine(betLines.takeProfitLine);
    if (betLines.stopLossLine) seriesRef.current.removePriceLine(betLines.stopLossLine);

    if (!betOnAsset) {
      setBetLines({});
      return;
    }

    const newLines: BetLines = {};

    if (betOnAsset.openPrice) {
      newLines.entryPriceLine = seriesRef.current.createPriceLine({
        price: betOnAsset.openPrice,
        color: '#9096AF',
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'Entry',
      });
    }
    if (betOnAsset.bustPrice) {
      newLines.bustPriceLine = seriesRef.current.createPriceLine({
        price: betOnAsset.bustPrice,
        color: '#C0180C',
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'Bust',
      });
    }
    if (betOnAsset.takeProfitPrice) {
      newLines.takeProfitLine = seriesRef.current.createPriceLine({
        price: betOnAsset.takeProfitPrice,
        color: '#0E8A50',
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'Take Profit',
      });
    }
    if (betOnAsset.stopLossPrice) {
      newLines.stopLossLine = seriesRef.current.createPriceLine({
        price: betOnAsset.stopLossPrice,
        color: '#C0180C',
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'Stop Loss',
      });
    }

    setBetLines(newLines);
  }, [betOnAsset, seriesRef.current]);

  return (
    <div className={cn(style.tvChartWrapper)}>
      {!chartReady && (
        <div className={style.loader}>
          <div className={style.loader} />
        </div>
      )}
      <div className={cn(style.tvChart, style.chart_container)}>
        <div ref={chartContainerRef} className={cn(style.tvChart)} style={{ opacity: chartReady ? 1 : 0 }} />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            overflow: 'hidden',
            width: 'calc(100% - 72px)',
          }}
        >
          {markerPositions.map((mp) => (
            <BetMarker key={mp.id} coordinates={{ x: mp.x, y: mp.y }} bet={mp.bet} />
          ))}
        </div>

        <button
          className={style.toNowButton}
          onClick={moveForward}
          style={{
            opacity: canMoveForward ? 1 : 0,
            cursor: canMoveForward ? 'pointer' : 'default',
          }}
        >
          <DoubleRightOutlined />
        </button>
      </div>
    </div>
  );
};

export default LightweightChart;
