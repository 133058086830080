import React from 'react';

type FormattedAmountProps = {
  amount: number;
  showCurrency?: boolean;
};

export default function FormattedAmountShort({ amount, showCurrency = true }: FormattedAmountProps) {
  const getPrice = () => {
    if (amount >= 1_000_000_000) {
      return (amount / 1_000_000_000).toFixed(2).replace(/\.00$/, '') + 'B';
    } else if (amount >= 1_000_000) {
      return (amount / 1_000_000).toFixed(2).replace(/\.00$/, '') + 'M';
    } else if (amount >= 1_000) {
      return (amount / 1_000).toFixed(2).replace(/\.00$/, '') + 'K';
    }
    return amount.toString();
  };

  return <>{getPrice()}</>;
}
