'use client';
import cn from 'classnames';

import { OptionType } from '../TabsContainer';

import styles from './CustomSegmented.module.scss';

interface SegmentedProps {
  items: OptionType[];
  activeIndex: number;
  onChangeActiveIndex: (index: number) => void;
}

export default function CustomSegmented({ items, activeIndex, onChangeActiveIndex }: SegmentedProps) {
  const handleClick = (value: number) => {
    onChangeActiveIndex?.(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.toggle}>
        <div className={styles.inner}>
          {items.map((item) => (
            <div
              key={item.index}
              role='presentation'
              className={cn(styles.item, item.index === activeIndex && styles.item_active)}
              onClick={() => handleClick(item.index)}
            >
              {item.label}
            </div>
          ))}
          <div
            className={styles.slicer}
            style={{
              transform: `translateX(${activeIndex * 100}%)`,
              width: `${100 / items.length}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
