import { useCallback, useEffect, useRef } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { API } from '../api/uri';
import { FatalError, RetriableError } from '../helpers/EventSourceErrors';
import { useAppDispatch } from '../store';
import { updateAvailableAssets } from '../store/assets/slices';

export function useSubscribeOnAvailableAssets() {
  const availableAssetsControllerRef = useRef(new AbortController());
  const dispatch = useAppDispatch();
  const firstTimeRenderRef = useRef<boolean>(true);

  const subscribeOnAvailableAssets = useCallback(async () => {
    availableAssetsControllerRef.current = new AbortController();
    await fetchEventSource(`${API.ASSET_PRICE_UPDATE}/updates`, {
      signal: availableAssetsControllerRef.current.signal,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const data = JSON.parse(message.data);
          if (typeof data === 'object') {
            dispatch(updateAvailableAssets({ data, firstTimeRenderRef }));
          }
        } catch (e) {
          console.log('error parse message');
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          if (process.env.NODE_ENV === 'development') {
            console.log(process.env);
            console.log('Error get price update, status:');
          } else {
            throw err;
          }
        } else {
          console.log('Try to reconnect');
          // do nothing to automatically retry. You can also return a specific retry interval here.
        }
      },
    });
  }, []);

  useEffect(() => {
    subscribeOnAvailableAssets();

    return () => {
      console.log('abort called available assets');
      if (availableAssetsControllerRef.current) {
        availableAssetsControllerRef.current.abort();
      }
    };
  }, [subscribeOnAvailableAssets]);
}
