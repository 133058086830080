export enum Prediction {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum BetStatus {
  Active = 'ACTIVE',
  CLOSED = 'CLOSED',
  BUSTED = 'BUSTED',
}

export enum CommissionType {
  PNL_FEE = 'PNL_FEE',
  FLAT_FEE = 'FLAT_FEE',
}

export enum BetType {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export interface Asset {
  id: number;
  name: string;
  symbol: string;
  type?: string;
  isActive: boolean;
  icon?: string;
  multiplierMin?: number;
  multiplierMax?: number;
  takeProfitLimit?: number;
  stopLossLimit?: number;
  flatFeePercent?: number;
  pnlFeePercent?: number;
  fundingFeePercent?: number;
  fundingFeeTimeframe?: number;
  lastPrice?: number;
  change?: number;
  high?: number;
  low?: number;
  volume24h?: number;
  updateTime?: number;
}

export interface Bet {
  id: number;
  openPrice: number;
  bustPrice: number;
  amount: number;
  multiplier: number;
  prediction: Prediction;
  isPublic?: boolean;
  status?: BetStatus;
  playerCurrency: string;
  commissionType?: CommissionType;
  lastModifiedAt?: string;
  openedAt?: string;
  closedAt?: string;
  playerId?: number;
  assetId?: number;
  assetIcon?: string;
  assetName: string;
  takeProfitPrice?: number;
  stopLossPrice?: number;
  closePrice?: number;
  profit?: number;
  playerNickname?: string;
}

export interface ActiveBet extends Bet {
  takeProfitPrice: number;
  stopLossPrice: number;
  closePrice?: number;
  profit?: number;
  commissionFee?: number;
  fundingFeePercent?: number;
  fundingFeeTimeframe?: number;
  lastFundingFeeTime?: number;
  fundingFeeAmount?: number;
}

export interface BetOnChart {
  id: number;
  stopLossPrice: number;
  takeProfitPrice: number;
  openPrice: number;
  bustPrice: number;
}

export interface ClosedBet extends Bet {
  roi?: number;
  commissionFee?: number;
}

export interface PublicBet extends Bet {
  playerName: string;
  playerImageUrl?: string;
  roi?: number;
}

export interface LeaderboardItem extends Bet {
  playerName: string;
  playerImageUrl?: string;
  roi?: number;
}

export interface Player {
  id: number;
  userName?: string;
  photoUrl?: string;
}
