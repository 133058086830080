export const formatNumber = (value: number, isCurrency: boolean = false, fractionDigits = 2): string => {
  const baseOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: fractionDigits,
  };

  const formatOptions: Intl.NumberFormatOptions = isCurrency
    ? {
        ...baseOptions,
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      }
    : baseOptions;

  return new Intl.NumberFormat('en-US', formatOptions).format(value);
};
