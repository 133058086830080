import React, { ChangeEvent, FC } from 'react';
import { NumericFormat } from 'react-number-format';
import cn from 'classnames';

import { formatNumber } from '../../../../../helpers/formatNumber';
import { getDecimalPlaces } from '../../../../../helpers/getDecimalPlaces';

import styles from './Display.module.scss';

interface DisplayProps {
  multiplierValue: number;
  bustPrice: number;
  entryPrice: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Display: FC<DisplayProps> = ({ multiplierValue, bustPrice, entryPrice, onChange }) => {
  const fractionDigits = getDecimalPlaces(entryPrice);

  return (
    <div className={styles.display}>
      <div className={styles.display_element}>
        <p className={styles.display_element_label}>Multiplier</p>
        <div className={styles.display_element_inputWrapper}>
          <div className={styles.display_element_inputWrapper_input}>
            <span>x</span>
            <NumericFormat
              thousandSeparator={false}
              decimalScale={2}
              fixedDecimalScale={false}
              allowNegative={false}
              placeholder=''
              inputMode='numeric'
              value={multiplierValue}
              onChange={onChange}
              isAllowed={(values) => {
                const { formattedValue, floatValue = 0 } = values;
                return formattedValue === '' || floatValue <= 1000;
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.display_element}>
        <p className={styles.display_element_label}>Bust price</p>
        {multiplierValue ? (
          <div className={styles.display_element_blockWrapper}>
            <div className={styles.display_element_blockWrapper_block}>
              $ {formatNumber(bustPrice, false, fractionDigits)}
            </div>
          </div>
        ) : (
          <div className={styles.display_element_blockWrapper}>
            <div className={cn(styles.display_element_blockWrapper_block, styles.display_element_block_valid)}>
              Enter valid multiplier
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Display;
