import React from 'react';
import { NumericFormat } from 'react-number-format';

type FormattedAmountProps = {
  amount: number;
  showCurrency?: boolean;
  fractionDigits?: number;
};

export default function FormattedAmount({ amount, showCurrency = true, fractionDigits = 2 }: FormattedAmountProps) {
  return (
    <NumericFormat
      thousandSeparator={true}
      decimalScale={fractionDigits}
      fixedDecimalScale={true}
      prefix={showCurrency ? '$' : ''}
      placeholder=''
      value={amount}
      displayType='text'
    />
  );
}
