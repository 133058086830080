import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useCurrencyFromPath = () => {
  const { pathname } = useLocation();
  const [currency, setCurrency] = useState<string | null>('');

  useEffect(() => {
    const pathParts = pathname.split('/').filter(Boolean);
    setCurrency(pathParts.length > 0 ? pathParts[0] : null);
  }, [pathname]);

  return currency;
};
