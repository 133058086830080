import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { Splitter } from 'antd';

import { API } from '../../api/uri';
import { QuestionMarkIcon } from '../../assets/icons';
import BetWidgetDrawer from '../../components/BetWidgetDriver/BetWidgetDrawer';
import ChartWidget from '../../components/ChartWidget/ChartWidget';
import { useCurrencyFromPath } from '../../components/LightWeightChart/hooks/useCurrencyFromPath';
import TableTabsNew from '../../components/TabHeaderNew/TableTabsNew';
import { FatalError, RetriableError } from '../../helpers/EventSourceErrors';
import { useShowMessage } from '../../helpers/messages';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setActiveBets, setNewClosedBet, setPublicBets } from '../../store/betList/slices';
import { setBetWidgetDrawerVisible, setMenuDrawerVisible } from '../../store/ui/slices';

import BetWidget from './BetWidget/BetWidget';

import styles from './BetPage.module.scss';

export default function MainPage() {
  const { isMobile } = useWindowSize();
  const activeBetsAbortControllerRef = useRef(new AbortController());
  const closedBetsAbortControllerRef = useRef(new AbortController());
  const publicBetsAbortControllerRef = useRef(new AbortController());

  const currentCurrency = useCurrencyFromPath();

  const isFirstRef = useRef<boolean>(true);

  const dispatch = useAppDispatch();
  const { playerId, urlParams } = useTypedSelector((state) => state.user);
  const { size } = useTypedSelector((state) => state.betList.activeBetsPagination);
  const { showCashOutMessage, contextHolder } = useShowMessage();

  const showMenu = useCallback(() => {
    dispatch(setBetWidgetDrawerVisible(false));
    dispatch(setMenuDrawerVisible(true));
  }, [dispatch]);

  const urlParamsString = useMemo(() => {
    if (typeof urlParams === 'object') {
      return Object.entries(urlParams || {})
        .map((param) => param.map((s) => encodeURIComponent(s)).join('='))
        .join('&');
    }
    return null;
  }, [urlParams]);

  const subscribeOnActiveBets = useCallback(async () => {
    console.log('Start get bets');
    activeBetsAbortControllerRef.current = new AbortController();
    await fetchEventSource(`${API.PLAYER_BETS_LIST}/${playerId}/active?page=0&size=${size}&${urlParamsString}`, {
      signal: activeBetsAbortControllerRef.current.signal,
      // openWhenHidden: true,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const data = JSON.parse(message.data);
          const bets = data.content;

          if (Array.isArray(bets)) {
            dispatch(setActiveBets(bets));
          }
        } catch (e) {
          console.log('error parse message');
          console.log({ e });
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          // throw err;
          console.log('Fatal error get open bets');
        } else {
          console.log('Try to reconnect');
          // do nothing to automatically retry. You can also return a specific retry interval here.
        }
      },
    });
  }, [playerId, urlParamsString, size]);

  const subscribeOnClosedBets = useCallback(async () => {
    console.log('Start get closed bets');
    closedBetsAbortControllerRef.current = new AbortController();
    await fetchEventSource(`${API.PLAYER_BETS_LIST}/${playerId}/closed/bets?${urlParamsString}`, {
      signal: activeBetsAbortControllerRef.current.signal,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const bets = JSON.parse(message.data);
          if (Array.isArray(bets)) {
            bets.forEach((item) => {
              if (item.betDetails?.id) {
                showCashOutMessage(item.betDetails);
              }
            });
            dispatch(setNewClosedBet(bets.map((i) => i.betDetails)));
          }
        } catch (e) {
          console.log('error parse message');
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          // throw err;
          console.log('Fatal error get closed bets');
        } else {
          console.log('Try to reconnect');
        }
      },
    });
  }, [playerId, urlParamsString, dispatch]);

  const subscribeOnPublicBets = useCallback(async () => {
    console.log('Start get public bets');
    publicBetsAbortControllerRef.current = new AbortController();

    await fetchEventSource(`${API.PUBLIC_BETS}?symbol=${currentCurrency}USD`, {
      signal: publicBetsAbortControllerRef.current.signal,
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(message) {
        try {
          const bets = JSON.parse(message.data);

          if (Array.isArray(bets)) {
            dispatch(
              setPublicBets({
                bets,
                isFirstRender: isFirstRef.current,
              }),
            );

            if (isFirstRef.current) {
              isFirstRef.current = false;
            }
          }
        } catch (e) {
          console.log('error parse message');
        }
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof FatalError) {
          // throw err;
          console.log('Fatal error get closed bets');
        } else {
          console.log('Try to reconnect');
        }
      },
    });
  }, [currentCurrency, dispatch]);

  useEffect(() => {
    if (playerId && urlParamsString) {
      subscribeOnClosedBets();
    }
    return () => {
      console.log('abort called closed bets');

      if (closedBetsAbortControllerRef.current) {
        closedBetsAbortControllerRef.current.abort();
      }
    };
  }, [urlParamsString, playerId]);

  useEffect(() => {
    if (playerId && urlParamsString) {
      subscribeOnActiveBets();
    }
    return () => {
      console.log('abort called active bet');
      if (activeBetsAbortControllerRef.current) {
        activeBetsAbortControllerRef.current.abort();
      }
    };
  }, [urlParamsString, playerId, subscribeOnActiveBets]);

  useEffect(() => {
    if (currentCurrency) {
      subscribeOnPublicBets();
    }
    return () => {
      console.log('abort called public bets');
      if (publicBetsAbortControllerRef.current) {
        publicBetsAbortControllerRef.current.abort();
      }
    };
  }, [subscribeOnPublicBets, currentCurrency]);

  if (isMobile) {
    return (
      <>
        {contextHolder}
        <Splitter layout={'vertical'} className={styles.splitter}>
          <Splitter.Panel min={200}>
            <div className={styles.chartContainerMobile} id='chart-container-mobile'>
              <ChartWidget />
              <div className={styles.menuButton} onClick={showMenu}>
                <QuestionMarkIcon />
              </div>
            </div>
          </Splitter.Panel>
          <Splitter.Panel min={10}>
            <div className={styles.tableContainer}>
              {/*<TableTabs />*/}
              <TableTabsNew />
            </div>
          </Splitter.Panel>
        </Splitter>
        <BetWidgetDrawer />
      </>
    );
  }

  return (
    <div className={styles.pageContainer}>
      {contextHolder}
      <div className={styles.betContainer}>
        <div className={styles.chartContainer}>
          <ChartWidget />
        </div>
        <BetWidget />
      </div>

      <div className={styles.tableContainer}>
        {/*<TableTabs />*/}
        <TableTabsNew />
      </div>
    </div>
  );
}
