import React, { useEffect, useMemo, useState } from 'react';
import { numericFormatter } from 'react-number-format';
import { usePrevious } from 'react-use';
import Icon from '@ant-design/icons';
import cn from 'classnames';

import { ArrowDownIcon, ArrowUpIcon } from '../../../assets/icons';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { priceFormat } from '../../LightWeightChart/helpers';

import styles from './CurrentPrice.module.scss';

export default function CurrentPrice() {
  const { currentAsset } = useTypedSelector((state) => state.assets);
  const prevPrice = usePrevious(currentAsset?.lastPrice);
  const [direction, setDirection] = useState('');

  useEffect(() => {
    if (prevPrice && currentAsset?.lastPrice) {
      if (prevPrice < currentAsset?.lastPrice) setDirection('UP');
      if (prevPrice > currentAsset?.lastPrice) setDirection('DOWN');
    }
  }, [currentAsset?.lastPrice, prevPrice]);

  if (!currentAsset?.lastPrice || Number.isNaN(currentAsset?.lastPrice)) return null;

  return (
    <span
      className={cn(styles.price, direction === 'UP' && styles.price_up, direction === 'DOWN' && styles.price_down)}
    >
      {priceFormat(currentAsset?.lastPrice)}
      {direction === 'UP' && <Icon component={ArrowUpIcon} className={styles.icon} />}
      {direction === 'DOWN' && <Icon component={ArrowDownIcon} className={styles.icon} />}
    </span>
  );
}
