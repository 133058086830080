export const getDecimalPlaces = (num: number) => {
  if (num > 100) {
    return 2;
  } else if (num >= 1) {
    return 4;
  } else if (num >= 0.05) {
    return 6;
  } else {
    const decimalPart = num.toString().split('.')[1] || '';
    if (decimalPart.length >= 3 && decimalPart.startsWith('000')) {
      return 8;
    }
    return 6;
  }
};
