import React from 'react';

import ActiveBets from '../../../pages/ActiveBets/ActiveBets';
import ClosedBets from '../../../pages/ClosedBets/ClosedBets';
import LeaderBoard from '../../../pages/LeaderBoard/LeaderBoard';
import PublicBets from '../../../pages/PublicBets/PublicBets';

interface ItemsProps {
  activeIndex: number;
  selectedType: string;
  selectedTimeframe: string;
  handleSelectType: (value: string) => void;
  handleSelectTimeframe: (value: string) => void;
}

const Items = ({
  activeIndex,
  selectedType,
  selectedTimeframe,
  handleSelectType,
  handleSelectTimeframe,
}: ItemsProps) => {
  const components = [
    <ActiveBets />,
    <ClosedBets />,
    <PublicBets />,
    <LeaderBoard
      selectedType={selectedType}
      selectedTimeframe={selectedTimeframe}
      onSelectType={handleSelectType}
      onSelectTimeframe={handleSelectTimeframe}
    />,
  ];

  return <>{components[activeIndex]}</>;
};

export default Items;
