import React, { ReactNode } from 'react';
import Icon from '@ant-design/icons';

import { ActiveBetsIcon, ClosedBetsIcon, LeaderboardIcon, PublicBetsIcon } from '../../../assets/icons';
import useWindowSize from '../../../hooks/useWindowSize';
import LeaderboardFilters from '../../LeaderboardFilters/LeaderboardFilters';

import CustomSegmented from './CustomSegmented/CustomSegmented';

import styles from './TabsContainer.module.scss';

interface TabLabelProps {
  icon: any;
  label: string;
}

export type OptionType = {
  label: ReactNode;
  index: number;
};

const TabLabel = ({ icon: IconComponent, label }: TabLabelProps) => (
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
    <Icon component={IconComponent} className={styles.icon} /> {label}
  </span>
);

const options: OptionType[] = [
  {
    label: <TabLabel icon={ActiveBetsIcon} label='Active Bets' />,
    index: 0,
  },
  {
    label: <TabLabel icon={ClosedBetsIcon} label='Closed Bets' />,
    index: 1,
  },
  {
    label: <TabLabel icon={PublicBetsIcon} label='Public Bets' />,
    index: 2,
  },
  {
    label: <TabLabel icon={LeaderboardIcon} label='Leaderboard' />,
    index: 3,
  },
];

const TabsContainer = ({
  activeIndex,
  onChangeActiveIndex,
  selectedType,
  selectedTimeframe,
  onSelectType,
  onSelectTimeframe,
}: any) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.wrapper}>
      <div className={styles.segmentedWrapper}>
        <CustomSegmented items={options} activeIndex={activeIndex} onChangeActiveIndex={onChangeActiveIndex} />
      </div>
      {activeIndex === 3 && !isMobile && (
        <LeaderboardFilters
          selectedType={selectedType}
          selectedTimeframe={selectedTimeframe}
          onSelectType={onSelectType}
          onSelectTimeframe={onSelectTimeframe}
        />
      )}
    </div>
  );
};

export default TabsContainer;
