import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import { ArrowUpIcon } from '../../assets/icons';
import { PublicBet } from '../../types/entities';

import styles from './index.module.scss';

type Props = {
  coordinates: { x: number; y: number };
  bet: PublicBet;
};
export const BetMarker: FC<Props> = ({ coordinates, bet }) => {
  const assetName = useMemo(() => {
    return bet.assetName.split('/')[0] || '';
  }, [bet]);

  const pnlValue = useMemo(() => {
    if (Number(bet.profit) > 0) {
      return `+${bet.profit}`;
    } else if (Number(bet.profit) < 0) {
      return `${bet.profit}`;
    } else {
      return 0;
    }
  }, [bet.profit]);

  const roiValue = useMemo(() => {
    if (Number(bet.roi) > 0) {
      return `+${bet.roi}%`;
    } else if (Number(bet.roi) < 0) {
      return `${bet.roi}%`;
    } else {
      return '0%';
    }
  }, [bet.roi]);

  const isArrowDown = bet.prediction === 'DOWN';

  const roiColor = useMemo(() => {
    if (Number(bet.roi) > 0) {
      return styles.priceUp;
    } else if (Number(bet.roi) < 0) {
      return styles.priceDown;
    } else {
      return styles.priceZero;
    }
  }, [bet.roi]);

  const pnlColor = useMemo(() => {
    if (Number(bet.profit) > 0) {
      return styles.priceUp;
    } else if (Number(bet.profit) < 0) {
      return styles.priceDown;
    } else {
      return styles.priceZero;
    }
  }, [bet.profit]);

  if (coordinates.x <= 0 || coordinates.y <= 0) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{
        left: `${coordinates?.x}px`,
        top: `${coordinates?.y}px`,
        display: !coordinates?.x || !coordinates.y ? 'none' : 'block',
      }}
    >
      <div className={styles.header}>
        <img alt='currency' className={styles.cryptoImage} src={bet.assetIcon} />
        <span className={styles.title}>{assetName}</span>
        {isArrowDown ? (
          <ArrowUpIcon width={20} height={20} className={styles.arrowDownIcon} />
        ) : (
          <ArrowUpIcon width={20} height={20} className={styles.arrowIcon} />
        )}
      </div>

      <div className={styles.bottom}>
        <div className={styles.price}>
          PNL<span className={cn(styles.markedPrice, pnlColor)}>{pnlValue}</span>
        </div>
        <div className={styles.price}>
          ROI<span className={cn(styles.markedPrice, roiColor)}>{roiValue}</span>
        </div>
      </div>
    </div>
  );
};
