import React, { useCallback, useMemo, useState } from 'react';
import { numericFormatter } from 'react-number-format';
import Icon from '@ant-design/icons';
import { Modal } from 'antd';
import cn from 'classnames';

import { ArrowDownIcon, ArrowUpIcon, EqualizerActiveIcon, EqualizerIcon, LinkIcon, Logo } from '../../assets/icons';
import BTC from '../../assets/images/btc.png';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setBetInfoModalVisible, setBetSettingsModalVisible } from '../../store/ui/slices';
import { Prediction } from '../../types/entities';
import CommonButton from '../CommonButton/CommonButton';
import ShareBettingResult from '../ShareBettingResult/ShareBettingResult';
import BetStatusCell from '../TableCells/BetStatusCell/BetStatusCell';

import PNL from './PNL/PNL';
import ShareComponent from './ShareComponent/ShareComponent';
import { useShareImage } from './useShareImage';

import styles from './BetInfoModal.module.scss';

export default function BetInfoModal() {
  const { playerId } = useTypedSelector((state) => state.user);
  const { height, width, isMobile } = useWindowSize();
  const { betInfoModalVisible: visible } = useTypedSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const { selectedBet: bet } = useTypedSelector((state) => state.betList);
  const [cashOutPending, setCashOutPending] = useState(false);

  const { shareImageLink, captureRef, loading, onOpenShareBetPopup, clearShareImageLink } = useShareImage(bet?.id);

  const isAutoBet = false; // TODO: set from bet object;

  const top = useMemo(() => {
    if (bet) {
      const el = document.getElementById(`${bet.id}bet`);
      if (el) {
        const rect = el.getBoundingClientRect?.();
        if (rect?.bottom - 450 > 0) {
          return rect?.bottom - height / 2 - 220;
        }
        if (rect?.top + 145 < height / 2) {
          return rect?.bottom - height / 2 + 210;
        }
        return 0;
      }
    }
    return 0;
  }, [bet, height]);

  const isActive = useMemo(() => {
    return bet?.playerId === playerId && !bet?.closePrice;
  }, [playerId, bet]);

  const handleCloseModal = useCallback(() => {
    clearShareImageLink();
    dispatch(setBetInfoModalVisible(false));
  }, [dispatch]);

  const onCashOut = useCallback(() => {
    console.log('Start cash out bet:', bet);
    setCashOutPending(true);
    setTimeout(() => {
      setCashOutPending(false);
      dispatch(setBetInfoModalVisible(false));
    }, 2000);
  }, [bet, dispatch]);

  const onBetSettings = useCallback(() => {
    dispatch(setBetSettingsModalVisible(true));
    dispatch(setBetInfoModalVisible(false));
  }, [dispatch]);

  const entryPrice = useMemo(() => {
    if (bet) {
      return numericFormatter(String(bet.openPrice), {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: '$',
      });
    }
    return '';
  }, [bet]);

  const bustPrice = useMemo(() => {
    if (bet) {
      return numericFormatter(String(bet.bustPrice), {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: '$',
      });
    }
    return '';
  }, [bet]);

  const footer = useCallback(
    () =>
      isActive ? (
        <div className={styles.footer}>
          <CommonButton
            type='secondary'
            onClick={onBetSettings}
            containerClassName={styles.settingsButton}
            icon={<Icon component={isAutoBet ? EqualizerActiveIcon : EqualizerIcon} className={styles.buttonIcon} />}
          />
          <CommonButton
            type='primaryGreen'
            onClick={onCashOut}
            label='Cash out'
            containerClassName={styles.cashOutButton}
            loading={cashOutPending}
          />
        </div>
      ) : (
        <div className={styles.footer}>
          <CommonButton
            type='secondary'
            onClick={onOpenShareBetPopup}
            label='Share bet details'
            containerClassName={styles.confirmButton}
            icon={<Icon component={LinkIcon} className={styles.icon} />}
            loading={loading}
            disabled={loading}
          />
          {shareImageLink && <ShareComponent link={shareImageLink} />}
          <ShareBettingResult captureRef={captureRef} bet={bet} />
        </div>
      ),
    [onCashOut, isActive, isAutoBet, onBetSettings, onOpenShareBetPopup, cashOutPending],
  );

  return (
    <Modal
      open={visible}
      onCancel={handleCloseModal}
      centered
      style={isMobile ? {} : { top: top, left: width / 2 - 230 }}
      width={346}
      className={styles.modal}
      footer={footer}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo />
        </div>
        <div className={styles.betHeaderBlock}>
          <div className={styles.row}>
            <span className={styles.label}>Bet</span>
            <div className={styles.row}>
              <img src={BTC} alt='' className={styles.coinImage} />
              <Icon
                component={bet?.prediction === Prediction.UP ? ArrowUpIcon : ArrowDownIcon}
                className={bet?.prediction === Prediction.UP ? styles.arrow_up : styles.arrow_down}
              />
            </div>
          </div>

          {/*<div className={styles.row}>*/}
          {/*  <span className={styles.label}>Player</span>*/}
          {/*  <div className={styles.row}>*/}
          {/*    /!*<img src={player?.photoUrl} alt='' className={styles.userImage} />*!/*/}
          {/*    <span>{bet?.playerNickname}</span>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className={styles.row}>
            <span className={styles.label}>Open date</span>
            <div className={styles.row}>
              <span>{new Date().toLocaleDateString()}</span>
            </div>
          </div>
        </div>

        <div>
          <div className={cn(styles.row, styles.fullWidth)}>
            <div className={styles.column}>
              <span className={styles.value}>{entryPrice}</span>
              <span className={styles.label}>Entry Price</span>
            </div>
            <div className={styles.column}>
              <span className={styles.value}>{bustPrice}</span>
              <span className={styles.label}>Bust Price</span>
            </div>
          </div>

          <div className={cn(styles.row, styles.fullWidth)}>
            <div className={styles.column}>
              <span className={styles.value}>{`$${bet?.amount}`}</span>
              <span className={styles.label}>Wager amount</span>
            </div>
            <div className={styles.column}>
              <span className={styles.value}>
                <PNL value={bet?.profit} />
              </span>
              <span className={styles.label}>PNL</span>
            </div>
          </div>

          <div className={cn(styles.row, styles.fullWidth)}>
            <div className={styles.column}>
              <span className={styles.value}>{`x${bet?.multiplier}`}</span>
              <span className={styles.label}>Multiplier</span>
            </div>
            <div className={styles.column}>
              {bet?.status ? (
                <BetStatusCell status={bet?.status} className={styles.status} />
              ) : (
                <span className={cn(styles.value)} />
              )}
              <span className={styles.label}>Status</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
