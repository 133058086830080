import { formatDateString, makeApiRequest } from '../../../charting_library-data/source/helpers';
import { DEFAULT_INTERVAL } from '../../../constants/constatnts';
import { Bar } from '../helpers';

const resolutions = {
  TICK: 'TICK',
  '1T': 'TICK',
  '5S': 'FIVE_SEC',
  '15S': 'FIFTEEN_SEC',
  '30S': 'THIRTY_SEC',
  1: 'ONE_MIN',
  5: 'FIVE_MIN',
};

const lastBarsCache = new Map();

export type Resolutions = {
  '1T': 'TICK';
  '5S': 'FIVE_SEC';
  '15S': 'FIFTEEN_SEC';
  '30S': 'THIRTY_SEC';
  1: 'ONE_MIN';
  5: 'FIVE_MIN';
};

type PeriodParams = {
  from: number;
  to: number;
  firstDataRequest: boolean;
};

export const getBars = async (
  symbolInfo: any,
  resolution: keyof Resolutions,
  periodParams: PeriodParams,
  onHistoryCallback: (bars: Bar[]) => void,
  onErrorCallback: (error: any) => void,
) => {
  const { from, to, firstDataRequest } = periodParams;
  // console.log({ from, to, firstDataRequest });
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const urlParameters: Record<'symbolCode' | 'from' | 'to' | 'resolution' | 'timeZone', string> = {
    symbolCode: symbolInfo.full_name,
    from: formatDateString(from),
    to: formatDateString(to),
    resolution: resolutions[resolution] || resolutions[DEFAULT_INTERVAL],
    timeZone: timezone,
  };

  const query = Object.keys(urlParameters)
    .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof typeof urlParameters])}`)
    .join('&');
  try {
    const data = await makeApiRequest(`bars/${symbolInfo.type}?${query}`);
    if (!data) return;
    if ((data.Response && data.Response === 'Error') || data.length === 0) {
      // "noData" should be set if there is no data in the requested period.
      onHistoryCallback([]);
      return;
    }

    let bars: Bar[] = [];

    data.forEach((bar: any) => {
      if (bar.t >= from * 1000 && bar.t < to * 1000) {
        bars = [
          ...bars,
          {
            time: bar.t,
            low: bar.l,
            high: bar.h,
            open: bar.o,
            close: bar.c,
          },
        ];
      }
    });
    if (firstDataRequest) {
      lastBarsCache.set(symbolInfo.full_name, {
        ...bars[bars.length - 1],
      });
    }
    onHistoryCallback(bars);
  } catch (error) {
    onErrorCallback(error);
  }
};
