import { useEffect, useState } from 'react';

import { API } from '../../api/uri';

export default function useGetLeaderboard(selectedType?: string, selectedTimeframe?: string) {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams({
      type: selectedType?.toUpperCase() || 'PNL',
      timeframe: selectedTimeframe?.toUpperCase() || 'DAY',
      // assetId: 12345,
    }).toString();
    const eventSource = new EventSource(`${API.LEADERBOARD}?${params}`);
    eventSource.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        setData(parsedData);
        setLoading(false);
      } catch (error) {
        console.error('Error SSE:', error);
        setLoading(false);
      }
    };
    eventSource.onerror = (error) => {
      console.error('SSE error:', error);
      setLoading(false);
    };
    return () => {
      eventSource.close();
    };
  }, [selectedType, selectedTimeframe]);

  return { data, loading };
}
