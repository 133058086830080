import React from 'react';
import { TelegramIcon, TelegramShareButton, TwitterShareButton, XIcon } from 'react-share';

import styles from './ShareComponent.module.scss';

interface ShareComponentProps {
  link: string;
}

const ShareComponent = ({ link }: ShareComponentProps) => {
  return (
    <div className={styles.socialNetworks}>
      <TelegramShareButton url={link}>
        <TelegramIcon />
      </TelegramShareButton>
      <TwitterShareButton url={link}>
        <XIcon />
      </TwitterShareButton>
    </div>
  );
};

export default ShareComponent;
