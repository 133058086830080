import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import ActiveBetSettingsModal from '../ActiveBetSettingsModal/ActiveBetSettingsModal';
import BetInfoModal from '../BetInfo/BetInfoModal';

import Items from './Items/Items';
import TabsContainer from './TabsContainer/TabsContainer';

export default function TableTabsNew() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>('PNL');
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('day');
  const navigate = useNavigate();
  const currentAsset = useTypedSelector((state) => state.assets.currentAsset);

  const handleSelectType = (value: string) => {
    setSelectedType(value);
  };

  const handleSelectTimeframe = (value: string) => {
    setSelectedTimeframe(value);
  };

  const listOfTabs = ['active-bets', 'closed-bets', 'public-bets', 'leaderboard'];

  const handleChangeActiveIndex = (activeIdx: number) => {
    const tabName = listOfTabs[activeIdx];
    const currencyName = currentAsset?.name.split('/')[0] || '';

    const urlParams = window.location.search;

    navigate(`/${currencyName}/${tabName}${urlParams}`);

    setActiveIndex(activeIdx);
  };

  return (
    <>
      <TabsContainer
        activeIndex={activeIndex}
        onChangeActiveIndex={handleChangeActiveIndex}
        selectedType={selectedType}
        selectedTimeframe={selectedTimeframe}
        onSelectType={handleSelectType}
        onSelectTimeframe={handleSelectTimeframe}
      />
      <Items
        activeIndex={activeIndex}
        selectedType={selectedType}
        selectedTimeframe={selectedTimeframe}
        handleSelectType={handleSelectType}
        handleSelectTimeframe={handleSelectTimeframe}
      />
      <BetInfoModal />
      <ActiveBetSettingsModal />
    </>
  );
}
