import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import FormattedAmount from '../../components/FormattedAmount/FormattedAmount';
import TableLoader from '../../components/Loader/TableLoader';
import BetInfoCell from '../../components/TableCells/BetInfoCell/BetInfoCell';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import ROICell from '../../components/TableCells/ROICell/ROICell';
import { getDecimalPlaces } from '../../helpers/getDecimalPlaces';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import EmptyState from '../ActiveBets/EmptyState/EmptyState';

import styles from './index.module.scss';

export default function PublicBets() {
  const { width } = useWindowSize();

  const publicBetsQueue = useTypedSelector((state) => state.betList.publicBetsQueue);
  const loading = useTypedSelector((state) => state.betList.publicBetsLoading);

  const renderEmptyState = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.noDataPlaceholder}>
          <TableLoader isLoading={true} />
        </div>
      );
    } else if (publicBetsQueue.length === 0) {
      return (
        <div className={styles.noDataPlaceholder}>
          {/*<EmptyState description={['No public bets']} smallImg />*/}
          <EmptyState
            title={'Create a new bet'}
            text={'It looks like the bet list is empty right now. Start by placing your first bets!'}
          />
        </div>
      );
    }
  }, [loading, publicBetsQueue.length]);
  const columns = [
    {
      title: 'Bet',
      key: 'playerCurrency',
      width: 150,
      render: (_: any, record: any) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
      width: 80,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bustPrice',
      render: (price: number, record: any) => {
        const fractionDigits = getDecimalPlaces(record.openPrice);
        return <FormattedAmount amount={price} fractionDigits={fractionDigits} />;
      },
      width: 110,
    },
    {
      title: 'Exit price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      render: (value: number) => <PNLCell value={value} />,
      width: 80,
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      render: (value: number) => <ROICell value={value} />,
      width: 80,
    },
    {
      title: '',
      key: 'betInfoColumn',
      render: (_: any, bet: any) => <BetInfoCell bet={bet} />,
      fixed: width < 1030 ? ('right' as any) : undefined,
      width: 36,
    },
  ];

  return (
    <div className={styles.mainContainer}>
      {renderEmptyState()}
      <div className={styles.tableContainer}>
        <div className={styles.headerRow}>
          {columns.map((column, index) => (
            <div className={cn(styles.cell, styles.headerCell)} key={column.key}>
              {column.title}
            </div>
          ))}
        </div>
        <AnimatePresence>
          <motion.ul layout layoutId={'list'} className={styles.listContainer}>
            {publicBetsQueue.map((item, index) => {
              return (
                <motion.li
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={styles.dataRow}
                  key={item.id}
                >
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <BetPairCell coin={item.assetName} direction={item.prediction} image={item.assetIcon} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <span className={styles.highlighted}>
                      <FormattedAmount amount={item.amount} />
                    </span>
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <span className={styles.highlighted}>{`x${item.multiplier}`}</span>
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <FormattedAmount amount={item.openPrice} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <FormattedAmount amount={item.bustPrice} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <FormattedAmount amount={item.closePrice || 0} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <PNLCell value={item.profit || 0} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <ROICell value={item.roi || 0} />
                  </div>
                  <div className={cn(styles.cell, styles.dataCell)}>
                    <BetInfoCell bet={item} />
                  </div>
                </motion.li>
              );
            })}
          </motion.ul>
        </AnimatePresence>
      </div>
    </div>
  );
}
