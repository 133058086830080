import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_KEY } from '../../constants/constatnts';

import { getAssets } from './asyncThunks/slice';
import { AssetsSliceState } from './types';

const initialState: AssetsSliceState = {
  assets: [],
  availableAssets: [],
  currentAsset: null,
  resolution: null,
  loading: false,
  error: '',
  currentBarColor: undefined,
  assetUpdated: false,
};
const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setCurrentAsset: (state, action) => {
      state.currentAsset = action.payload;
      const assetName = action.payload.symbol;
      localStorage.setItem(STORAGE_KEY.LAST_OPENED_ASSET, assetName);
      // const lastSettingsString = localStorage.getItem(assetName);
      // if (!!lastSettingsString) {
      //   const lastSettings = JSON.parse(lastSettingsString);
      //   if (lastSettings?.resolution) {
      //     state.resolution = lastSettings.resolution;
      //   }
      // } else {
      //   localStorage.setItem(assetName, JSON.stringify({ resolution: '1T' }));
      //   state.resolution = '1T';
      // }
    },
    updateAvailableAssets: (state, action) => {
      const { data, firstTimeRenderRef } = action.payload;
      state.loading = false;
      state.availableAssets = data;

      const lastAssetName = localStorage.getItem(STORAGE_KEY.LAST_OPENED_ASSET) || 'BTCUSD';

      if (Array.isArray(data) && firstTimeRenderRef.current) {
        const lastAsset = data.find((asset) => asset.symbol === lastAssetName) || action.payload[0];

        if (lastAsset) {
          state.currentAsset = { ...lastAsset, lastPrice: lastAsset.lp };
          localStorage.setItem(STORAGE_KEY.LAST_OPENED_ASSET, lastAsset.symbol);
          const lastSettingsString = localStorage.getItem(lastAssetName);
          if (!!lastSettingsString) {
            const lastSettings = JSON.parse(lastSettingsString);
            if (lastSettings?.resolution) {
              state.resolution = lastSettings.resolution;
            }
          } else {
            state.resolution = '1T';
            localStorage.setItem(lastAssetName, JSON.stringify({ resolution: '1T' }));
          }
        }
        firstTimeRenderRef.current = false;
      }
    },
    updateAssetData: (state, action) => {
      if (typeof action.payload === 'object') {
        const { symbol, data } = action.payload;
        if (state.currentAsset && state.currentAsset?.symbol === symbol) {
          const prev = { ...state.currentAsset };
          // state.assetUpdated = !(prev.updateTime && prev.updateTime - data.t > 3000);
          state.currentAsset = {
            ...prev,
            lastPrice: data?.lp,
            high: data?.h,
            low: data.l,
            change: data?.cpd,
            volume24h: data?.v,
            updateTime: data?.t,
          };
        }
      }
    },
    setResolution: (state, action) => {
      state.resolution = action.payload;
      state.currentBarColor = undefined;
      const assetName = state.currentAsset?.symbol;
      if (assetName) {
        const lastSettingsString = localStorage.getItem(assetName);
        if (!!lastSettingsString) {
          const lastSettings = JSON.parse(lastSettingsString);
          lastSettings.resolution = action.payload;
          localStorage.setItem(assetName, JSON.stringify(lastSettings));
        } else {
          localStorage.setItem(assetName, JSON.stringify({ resolution: action.payload }));
        }
      }
    },
    setCurrentBarColor: (state, action) => {
      state.currentBarColor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.loading = false;
      state.assets = action.payload;
      const lastAssetName = localStorage.getItem(STORAGE_KEY.LAST_OPENED_ASSET) || 'BTCUSD';
      if (Array.isArray(action.payload)) {
        const lastAsset = action.payload.find((asset) => asset.symbol === lastAssetName) || action.payload[0];
        if (lastAsset) {
          state.currentAsset = lastAsset;
          localStorage.setItem(STORAGE_KEY.LAST_OPENED_ASSET, lastAsset.symbol);
          const lastSettingsString = localStorage.getItem(lastAssetName);
          if (!!lastSettingsString) {
            const lastSettings = JSON.parse(lastSettingsString);
            if (lastSettings?.resolution) {
              state.resolution = lastSettings.resolution;
            }
          } else {
            state.resolution = '1T';
            localStorage.setItem(lastAssetName, JSON.stringify({ resolution: '1T' }));
          }
        }
      }
    });
    builder.addCase(getAssets.rejected, (state) => {
      state.loading = false;
      state.assets = [];
      state.error = 'Error';
    });
  },
});

export const { setCurrentAsset, updateAvailableAssets, setResolution, updateAssetData, setCurrentBarColor } =
  assetsSlice.actions;
export const assetsReducer = assetsSlice.reducer;
