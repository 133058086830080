import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { API } from '../../api/uri';
import { FatalError, RetriableError } from '../../helpers/EventSourceErrors';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { updateAssetData } from '../../store/assets/slices';
import LightweightChart from '../LightWeightChart';

import AssetHistoricalData from './AssetHistoricalData/AssetHistoricalData';
import CoinSelector from './CoinSelector/CoinSelector';
import CurrentPrice from './CurrentPrice/CurrentPrice';
import ResolutionSelector from './ResolutionSelector/ResolutionSelector';
import SettingsDropdown from './SettingsDropdown/SettingsDropdown';

import styles from './ChartWidget.module.scss';

export default function ChartWidget() {
  const { currentAsset, resolution } = useTypedSelector((state) => state.assets);
  const dispatch = useAppDispatch();
  const abortControllerRef = useRef(new AbortController());

  const symbol = useMemo(() => currentAsset?.symbol, [currentAsset?.symbol]);

  const subscribeOnAssetUpdate = useCallback(
    async (symbolName: string) => {
      abortControllerRef.current = new AbortController();
      await fetchEventSource(`${API.ASSET_PRICE_UPDATE}/${symbolName}/updates`, {
        signal: abortControllerRef.current.signal,
        async onopen(response) {
          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            return;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        onmessage(message) {
          try {
            const asset = JSON.parse(message.data);
            if (typeof asset === 'object') {
              dispatch(updateAssetData({ symbol: symbolName, data: asset }));
            }
          } catch (e) {
            console.log('error parse message');
          }
        },
        onclose() {
          throw new RetriableError();
        },
        onerror(err) {
          if (err instanceof FatalError) {
            if (process.env.NODE_ENV === 'development') {
              console.log(process.env);
              console.log('Error get price update, status:');
            } else {
              throw err;
            }
          } else {
            console.log('Try to reconnect');
            // do nothing to automatically retry. You can also return a specific retry interval here.
          }
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (symbol) {
      subscribeOnAssetUpdate(symbol);

      return () => {
        if (abortControllerRef.current) {
          console.log('currentAssetAbortControllerRef closed');
          abortControllerRef.current.abort();
        }
      };
    }
  }, [symbol, subscribeOnAssetUpdate]);

  return (
    <div className={styles.container}>
      {!!currentAsset && !!resolution && (
        <>
          <div className={styles.header}>
            <div className={styles.left}>
              <CoinSelector />
              <CurrentPrice />
            </div>
            <div className={styles.right}>
              <ResolutionSelector />
              <SettingsDropdown />
            </div>
          </div>
          <AssetHistoricalData />
        </>
      )}
      <LightweightChart interval={resolution} />
    </div>
  );
}
