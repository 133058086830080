import React from 'react';
import cn from 'classnames';
import { QRCodeSVG } from 'qrcode.react';

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg';
import LogoImg from '../../assets/images/logo.png';
import { formatNumber } from '../../helpers/formatNumber';

import styles from './ShareBettingResult.module.scss';

interface ShareBettingResultProps {
  captureRef: React.RefObject<HTMLDivElement>;
  bet: any;
}

const COIN_POKER_LINK = 'https://coinpoker.com';

export default function ShareBettingResult({ captureRef, bet }: ShareBettingResultProps) {
  const getBetRoi = () => {
    const roi = bet?.roi;
    return roi > 0 ? `+${roi}%` : `${roi}%`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} ref={captureRef}>
        <div className={styles.inner}>
          <div className={styles.top}>
            <img src={LogoImg} alt='img' />
          </div>
          <div className={styles.main}>
            <div className={styles.main_top}>
              <div className={styles.main_top_left}>
                <img src={bet?.assetIcon} alt='img' />
                <div className={styles.main_top_left_text}>{bet?.assetName}</div>
              </div>
              <div className={cn(styles.main_top_right, bet?.prediction === 'DOWN' && styles.main_top_right_down)}>
                {bet?.prediction === 'DOWN' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                {bet?.prediction === 'DOWN' ? 'DOWN' : 'UP'}
              </div>
            </div>
            <div className={cn(styles.main_center, bet?.roi < 0 && styles.main_center_red)}>{getBetRoi()}</div>
            <div className={styles.main_bottom}>
              <div>
                <div className={styles.main_bottom_price}>{formatNumber(bet?.openPrice, true)}</div>
                <div className={styles.main_bottom_text}>Entry Price</div>
              </div>
              <div>
                <div className={styles.main_bottom_price}>{formatNumber(bet?.closePrice, true)}</div>
                <div className={styles.main_bottom_text}>Exit Price</div>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottom_qrcode}>
              <div className={styles.bottom_qrcode_inner}>
                <QRCodeSVG value={COIN_POKER_LINK} size={70} />
              </div>
            </div>
            <div className={styles.bottom_text}>
              Visit the <span>CoinCasino</span> website and create an account for free
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
