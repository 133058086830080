import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Collapse, Radio, RadioChangeEvent, Tooltip } from 'antd';
import cn from 'classnames';

import { ArrowUpSecondaryIcon, InfoIcon, QuestionMarkIcon } from '../../../../assets/icons';
import MenuDrawer from '../../../../components/MenuDriver/MenuDrawer';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../../store';
import { changeCommissionTypeAction } from '../../../../store/bet/slices';
import {
  setBetWidgetDrawerVisible,
  setMenuDrawerVisible,
  setShowHowItWorks,
  setShowRiskWarning,
  setShowRoiCalculator,
} from '../../../../store/ui/slices';
import { CommissionType } from '../../../../types/entities';

import Popup from './Popup/Popup';

import styles from './FeeOptions.module.scss';

export default function FeeOptions() {
  const [open, setOpen] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const { isMobile } = useWindowSize();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const { showRoiCalculator } = useTypedSelector((state) => state.ui);
  const { commissionType } = useTypedSelector((state) => state.bet);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      dispatch(changeCommissionTypeAction(e.target.value));
    },
    [dispatch],
  );

  const handleOpenPopup = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (isMobile) {
        dispatch(setBetWidgetDrawerVisible(false));
        dispatch(setMenuDrawerVisible(true));
      } else {
        setOpenPopup((prev) => !prev);
      }
    },
    [dispatch, isMobile],
  );

  const handleClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  useClickAway(popupRef, (event: MouseEvent) => {
    const target = event.target as Node;
    if (iconRef.current && iconRef.current.contains(target)) {
      return;
    }
    handleClosePopup();
  });

  const handleOpenHowItWorksModal = useCallback(() => {
    handleClosePopup();
    dispatch(setShowHowItWorks(true));
  }, [dispatch, handleClosePopup]);

  const handleOpenRiskWarningModal = useCallback(() => {
    handleClosePopup();
    dispatch(setShowRiskWarning(true));
  }, [dispatch, handleClosePopup]);

  const handleOpenRoiCalculatorModal = useCallback(() => {
    handleClosePopup();
    dispatch(setShowRoiCalculator(true));
  }, [dispatch, handleClosePopup]);

  useEffect(() => {
    if (showRoiCalculator) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showRoiCalculator]);

  const PNLFeeTooltip = useMemo(
    () => (
      <span>
        <span className={cn(styles.tooltipText, styles.tooltipDescription)}>
          When using a PnL fee, you won’t incur any charges to open or close a trade. Instead, a small percentage of
          your profits (if you have any) is deducted when the trade is closed.
        </span>
        <span className={cn(styles.tooltipText)}>
          Check{' '}
          <span className={styles.linkText} onClick={handleOpenRoiCalculatorModal}>
            ROI Calculator
          </span>{' '}
          for more details
        </span>
      </span>
    ),
    [handleOpenRoiCalculatorModal],
  );

  const FlatFeeTooltip = useMemo(
    () => (
      <span>
        <span className={cn(styles.tooltipText, styles.tooltipDescription)}>
          The Flat Fee is a fixed percentage charged when opening and closing a trade, regardless of its outcome. This
          fee offers predictable costs for your trading activity.
        </span>
        <span className={cn(styles.tooltipText)}>
          Check{' '}
          <span className={styles.linkText} onClick={handleOpenRoiCalculatorModal}>
            ROI Calculator
          </span>{' '}
          for more details
        </span>
      </span>
    ),
    [handleOpenRoiCalculatorModal],
  );

  const OptionSelector = useCallback(() => {
    return (
      <div className={styles.main}>
        <Radio.Group onChange={handleRadioChange} value={commissionType} className={styles.radioGroup}>
          <Radio value={CommissionType.PNL_FEE} className={styles.customRadio}>
            <div className={styles.customRadio_inner}>
              <span
                className={cn(
                  styles.customRadio_inner_label,
                  commissionType === CommissionType.PNL_FEE && styles.customRadio_inner_label_checked,
                )}
              >
                PNL Fee
              </span>
              <Tooltip title={PNLFeeTooltip} trigger='click' zIndex={isMobile ? 200 : 1}>
                <InfoIcon onClick={(e) => e.preventDefault()} />
              </Tooltip>
            </div>
          </Radio>
          <Radio value={CommissionType.FLAT_FEE} className={styles.customRadio}>
            <div className={styles.customRadio_inner}>
              <span
                className={cn(
                  styles.customRadio_inner_label,
                  commissionType === CommissionType.FLAT_FEE && styles.customRadio_inner_label_checked,
                )}
              >
                Flat Fee
              </span>
              <Tooltip title={FlatFeeTooltip} trigger='click' zIndex={isMobile ? 200 : 1}>
                <InfoIcon onClick={(e) => e.preventDefault()} />
              </Tooltip>
            </div>
          </Radio>
        </Radio.Group>
        <p className={styles.main_text}>A portion of your profits (if any) is deducted when you close the trade.</p>
      </div>
    );
  }, [handleRadioChange, commissionType, FlatFeeTooltip, PNLFeeTooltip, isMobile]);

  return (
    <>
      <Collapse
        ghost
        className={styles.feeOptions}
        items={[
          {
            key: 1,
            label: (
              <div className={cn(styles.header_text, open && styles.header_text_active)} onClick={handleClick}>
                <span>Fee options</span>
                <ArrowUpSecondaryIcon
                  style={{ transform: open ? 'scaleY(1)' : 'scaleY(-1)', transition: '0.3s all', marginTop: 2 }}
                />
              </div>
            ),
            extra: isMobile ? null : (
              <div
                ref={iconRef}
                className={cn(styles.header_icon, openPopup && styles.header_icon_active)}
                onClick={handleOpenPopup}
              >
                <div className={styles.questionMarkIcon}>
                  <QuestionMarkIcon />
                </div>
                {openPopup && !isMobile && (
                  <Popup
                    popupRef={popupRef}
                    onOpenHowItWorksModal={handleOpenHowItWorksModal}
                    onOpenRiskWarningModal={handleOpenRiskWarningModal}
                    onOpenRoiCalculatorModal={handleOpenRoiCalculatorModal}
                  />
                )}
              </div>
            ),
            children: <OptionSelector />,
            showArrow: false,
          },
        ]}
      />
      {isMobile && (
        <MenuDrawer
          onOpenHowItWorksModal={handleOpenHowItWorksModal}
          onOpenRiskWarningModal={handleOpenRiskWarningModal}
          onOpenRoiCalculatorModal={handleOpenRoiCalculatorModal}
        />
      )}
    </>
  );
}
