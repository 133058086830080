import React from 'react';

import { ReactComponent as EmptyStateIcon } from '../../../assets/icons/empty-state.svg';

import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  title: string;
  text: string;
}

export default function EmptyState({ title, text }: EmptyStateProps) {
  return (
    <div className={styles.wrapper}>
      <EmptyStateIcon />
      <div className={styles.textBlock}>
        <div className={styles.textBlock_title}>{title}</div>
        <div className={styles.textBlock_text}>{text}</div>
      </div>
    </div>
  );
}
