import React from 'react';

import CustomSelect from '../CustomSelect/CustomSelect';

import styles from './LeaderboardFilters.module.scss';

interface LeaderboardFiltersProps {
  selectedType?: string;
  selectedTimeframe?: string;
  onSelectType?: (value: string) => void;
  onSelectTimeframe?: (value: string) => void;
}

export default function LeaderboardFilters({
  selectedType,
  selectedTimeframe,
  onSelectType,
  onSelectTimeframe,
}: LeaderboardFiltersProps) {
  return (
    <div className={styles.container}>
      <CustomSelect defaultValue='all' options={[{ value: 'all', label: 'All instruments' }]} onSelect={() => {}} />
      <CustomSelect
        defaultValue='pnl'
        options={[
          { value: 'pnl', label: 'PNL' },
          { value: 'roi', label: 'ROI' },
        ]}
        value={selectedType}
        onSelect={onSelectType}
        width={85}
      />
      <CustomSelect
        defaultValue='day'
        width={85}
        options={[
          { value: 'day', label: 'Day' },
          { value: 'week', label: 'Week' },
          { value: 'month', label: 'Month' },
        ]}
        value={selectedTimeframe}
        onSelect={onSelectTimeframe}
      />
    </div>
  );
}
