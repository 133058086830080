import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PublicBet } from '../../types/entities';

import { getActiveBets, getClosedBets, getMoreClosedBets } from './asyncThunks/slice';
import { BetListState } from './types';

const initialState: BetListState = {
  selectedBet: null,
  betOnChart: null,
  loading: false,
  publicBetsLoading: false,
  activeBets: [],
  closedBets: [],
  publicBets: [],
  publicBetsQueue: [],
  error: '',
  closedBetsPageParams: {
    number: 0,
    totalPages: 0,
    last: true,
  },
  activeBetsPagination: {
    size: 15,
  },
};

const betListSlice = createSlice({
  name: 'betList',
  initialState,
  reducers: {
    setSelectedBet: (state, action) => {
      state.selectedBet = action.payload;
    },
    setPublicBets: (state, action: PayloadAction<{ bets: PublicBet[]; isFirstRender: boolean }>) => {
      const { bets, isFirstRender } = action.payload;

      state.publicBetsLoading = true;

      if (isFirstRender) {
        state.publicBetsQueue = bets.sort(
          (a, b) => new Date(String(a.closedAt)).getTime() - new Date(String(b.closedAt)).getTime(),
        );
        state.publicBetsLoading = false;
      } else {
        const newBets = bets.filter((bet) => !state.publicBetsQueue.some((oldBet) => oldBet.id === bet.id));

        if (newBets.length === 0) {
          return;
        }

        let combined = [...state.publicBetsQueue, ...newBets];
        combined.sort((a, b) => new Date(String(b.closedAt)).getTime() - new Date(String(a.closedAt)).getTime());

        if (combined.length > 25) {
          combined = combined.slice(0, 25);
        }

        combined.sort((a, b) => new Date(String(a.closedAt)).getTime() - new Date(String(b.closedAt)).getTime());

        state.publicBetsQueue = combined;
        state.publicBetsLoading = false;
      }
    },
    setActiveBetsPagination: (state) => {
      state.activeBetsPagination = { size: state.activeBetsPagination.size + 15 };
    },
    setActiveBets: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.activeBets = action.payload;

        const firstBet = action.payload[0];

        if (firstBet) {
          if (!state.betOnChart) {
            state.betOnChart = {
              id: firstBet.id,
              stopLossPrice: firstBet.stopLossPrice,
              takeProfitPrice: firstBet.takeProfitPrice,
              openPrice: firstBet.openPrice,
              bustPrice: firstBet.bustPrice,
            };
          } else {
            if (
              state.betOnChart.id !== firstBet.id ||
              state.betOnChart.stopLossPrice !== firstBet.stopLossPrice ||
              state.betOnChart.takeProfitPrice !== firstBet.takeProfitPrice
            ) {
              state.betOnChart = {
                id: firstBet.id,
                stopLossPrice: firstBet.stopLossPrice,
                takeProfitPrice: firstBet.takeProfitPrice,
                openPrice: firstBet.openPrice,
                bustPrice: firstBet.bustPrice,
              };
            }
          }
          // if there isn't first bet, we don't need draw any lines
        } else {
          state.betOnChart = null;
        }

        if (state.selectedBet) {
          const newBetState = action.payload.find((i) => i.id === state.selectedBet?.id);
          if (newBetState) {
            state.selectedBet = newBetState;
          }
        }
      }
    },
    setNewClosedBet: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.closedBets = [...action.payload, ...state.closedBets];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActiveBets.fulfilled, (state, action) => {
      state.loading = false;
      state.activeBets = action.payload.content;
    });
    builder.addCase(getActiveBets.rejected, (state) => {
      state.loading = false;
      state.activeBets = [];
      state.error = 'Error';
    });

    builder.addCase(getClosedBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClosedBets.fulfilled, (state, action) => {
      state.loading = false;
      state.closedBets = action.payload?.content;
      const { totalPages, last, number } = action.payload;
      state.closedBetsPageParams = { totalPages, number, last };
    });
    builder.addCase(getClosedBets.rejected, (state) => {
      state.loading = false;
      state.closedBets = [];
      state.error = 'Error';
    });

    builder.addCase(getMoreClosedBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMoreClosedBets.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload.content)) {
        state.closedBets = [...state.closedBets, ...action.payload?.content];
      }
      const { totalPages, last, number } = action.payload;
      state.closedBetsPageParams = { totalPages, number, last };
    });
    builder.addCase(getMoreClosedBets.rejected, (state) => {
      state.loading = false;
      state.closedBets = [];
      state.error = 'Error';
    });

    // builder.addCase(getPublicBets.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getPublicBets.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.publicBets = action.payload.content;
    // });
    // builder.addCase(getPublicBets.rejected, (state) => {
    //   state.loading = false;
    //   state.publicBets = [];
    //   state.error = 'Error';
    // });
  },
});

export const { setSelectedBet, setActiveBets, setNewClosedBet, setActiveBetsPagination, setPublicBets } =
  betListSlice.actions;
export const betListReducer = betListSlice.reducer;
