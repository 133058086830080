import React from 'react';

import { useAppDispatch } from '../../../../store';
import { changeMultiplierValue } from '../../../../store/bet/slices';

import CustomSlider from './CustomSlider/CustomSlider';
import Display from './Display/Display';

import styles from './SliderComponent.module.scss';

interface SliderComponentProps {
  bustPrice: number;
  multiplierValue: number;
  entryPrice: number;
}

export default function SliderComponent({ bustPrice, multiplierValue, entryPrice }: SliderComponentProps) {
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (+newValue === 0) {
      dispatch(changeMultiplierValue(''));
    } else {
      dispatch(changeMultiplierValue(+newValue));
    }
  };

  const handleChangeSlider = (value: number) => {
    dispatch(changeMultiplierValue(value));
  };

  return (
    <div className={styles.wrapper}>
      <Display
        multiplierValue={multiplierValue}
        bustPrice={bustPrice}
        entryPrice={entryPrice}
        onChange={handleChange}
      />
      <CustomSlider value={multiplierValue} onChangeSlider={handleChangeSlider} />
    </div>
  );
}
