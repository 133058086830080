// useLoadOlderDataOnScroll.ts
import { useEffect, useRef } from 'react';
import { ITimeScaleApi, Time } from 'lightweight-charts';

type LoadOlderDataFn = () => Promise<void>;

/**
 * The hook subscribes to the range change (subscribeVisibleLogicalRangeChange),
 * and when the user scrolls to the left edge (fromIndex < thresholdIndex),
 * it calls your "loadOlderData" callback.
 */
export function useLoadOlderDataOnScroll(
  timeScale: ITimeScaleApi<Time> | null,
  loadOlderData: LoadOlderDataFn,
  recalcPositionsCb: () => void,
  thresholdIndex: number = 5,
) {
  const loadingRef = useRef(false);

  useEffect(() => {
    if (!timeScale) return;

    function handleRangeChange() {
      if (!timeScale) return;

      recalcPositionsCb();

      const logicalRange = timeScale.getVisibleLogicalRange();
      if (!logicalRange) return;

      const fromIndex = Math.floor(logicalRange.from);
      if (fromIndex < thresholdIndex && !loadingRef.current) {
        loadingRef.current = true;
        loadOlderData()
          .catch((err) => console.error('Load older data failed', err))
          .finally(() => {
            loadingRef.current = false;
          });
      }
    }

    timeScale.subscribeVisibleLogicalRangeChange(handleRangeChange);

    return () => {
      timeScale.unsubscribeVisibleLogicalRangeChange(handleRangeChange);
    };
  }, [timeScale, loadOlderData, thresholdIndex, recalcPositionsCb]);
}
