const { REACT_APP_FINANCE_SERVICE } = process.env;

export const API = {
  ASSET_PRICE_UPDATE: `${REACT_APP_FINANCE_SERVICE}/api/symbols/crypto`,
  PLAYER_BETS_LIST: `${REACT_APP_FINANCE_SERVICE}/api/players`,
  START_GAME: `https://casino-api.tgcloud.dev/api/v1/web/franchises/82/players`,
  LEADERBOARD: `${REACT_APP_FINANCE_SERVICE}/api/bets/leaderboard`,
  PUBLIC_BETS: `${REACT_APP_FINANCE_SERVICE}/api/bets/public`,
  testPublicBets: `${REACT_APP_FINANCE_SERVICE}/api/bets/1/public`,
};
