import React from 'react';
import { Segmented } from 'antd';

import styles from './BetWidgetSegmented.module.scss';

interface BetWidgetTabsProps {
  activeTab: string;
  onChangeActiveTab: (key: string) => void;
}

const options = [
  {
    label: (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        Manual
      </span>
    ),
    value: 'manual',
  },
  {
    label: (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        Auto
      </span>
    ),
    value: 'auto',
  },
];

export default function BetWidgetSegmented({ activeTab, onChangeActiveTab }: BetWidgetTabsProps) {
  return (
    <div className={styles.segmentedWrapper}>
      <Segmented options={options} value={activeTab} onChange={onChangeActiveTab} />
    </div>
  );
}
